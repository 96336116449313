import React, { createContext, useState } from "react";

import { ToastContainer, toast } from "react-toastify";

export const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const notify = (msg, Ttype) =>
    toast(msg, {
      type: Ttype,
    });
  return (
    <>
      <ToastContext.Provider
        value={{
          notify,
        }}
      >
        <ToastContainer theme="dark" />
        {children}
      </ToastContext.Provider>
    </>
  );
};
