// const GLOBAL_URL = "http://localhost:6001/api/user";
// const GLOBAL_URL_ADMIN = "http://localhost:6001/api/admin";
const GLOBAL_URL = "https://energyaudit.cag.org.in/api/user";
const GLOBAL_URL_ADMIN = "https://energyaudit.cag.org.in/api/admin";

function getAuth() {
  let token = localStorage.getItem("cag_token");
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
}
function getUserConfig() {
  return {
    headers: {
      "Content-Type": "application/json",
    },
  };
}

module.exports = {
  GLOBAL_URL,
  GLOBAL_URL_ADMIN,
  CONFIG: getAuth,
  USERCONFIG: getUserConfig,
};
