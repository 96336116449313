import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import "../assets/css/tool.css";
import logo from "../assets/img/logo.png";
import { Document, Page, pdfjs } from "react-pdf";
import "../assets/css/pdfView.css";
import { useLocation } from "react-router-dom";

import { Switch } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { IconContext } from "react-icons";
import { Fade } from "react-reveal";
import Bounce from "react-reveal/Bounce";
import Slide from "react-reveal/Slide";
import RubberBand from "react-reveal/RubberBand";
import { FaInfoCircle, FaNotesMedical } from "react-icons/fa";
import { MdElectricalServices } from "react-icons/md";
import { Modal, Button } from "react-bootstrap";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbReportAnalytics } from "react-icons/tb";

import { AiFillEdit } from "react-icons/ai";
import { FaFeather, FaLightbulb } from "react-icons/fa";
import { HiLightBulb } from "react-icons/hi";
import { FcIdea } from "react-icons/fc";

import { Formik, isInteger, useFormikContext } from "formik";
import * as yup from "yup";
import "yup-phone";
import axios from "axios";
import { ToastContext } from "../Context/ToastContext";
import Select from "react-select";
import { components, StylesConfig } from "react-select";
import { USERCONFIG, GLOBAL_URL } from "../config";
import quesIcon from "../assets/img/ques-1.png";
import { styled } from "@mui/material/styles";
import PdfViewer from "../components/PdfViewer";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 58,
  height: 30,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 4,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(28px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const monthOptions = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
];

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

function Tool() {
  const required = <span className="required">*</span>;
  const [personalInfo, setPersonalInfo] = useState(null);
  const [consumptionInfo, setConsumptionInfo] = useState(null);
  const [consumptionItems, setConsumptionItems] = useState([]);
  const [GlobalQuestionaire, setGlobalQuestionaire] = useState([]);
  const [pdfBlob, setBlob] = useState(null);
  const toaster = useContext(ToastContext);

  const [step, setSteps] = useState(1);
  const location = useLocation();

  // console.log("Parent renderded", location);

  const Personal = React.memo(() => {
    const initialValues = personalInfo
      ? {
          firstname: personalInfo.firstname,
          lastname: personalInfo.lastname,
          email: personalInfo.email,
          mobile: personalInfo.mobile,
          address1: personalInfo.address1,
          address2: personalInfo.address2,
          city: personalInfo.city,
          state: personalInfo.state,
          pincode: personalInfo.pincode,
          rooms: personalInfo.rooms,
          area: personalInfo.area,
          highmonth: personalInfo.highmonth,
        }
      : {
          firstname: "",
          lastname: "",
          email: "",
          mobile: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          pincode: "",
          rooms: "",
          area: "",
          highmonth: [],
        };
    return (
      <>
        <Formik
          initialValues={initialValues}
          validateOnChange
          validateOnBlur
          validationSchema={yup.object({
            firstname: yup.string().required("First Name is required"),
            lastname: yup.string().required("Last Name is required"),

            email: yup
              .string()
              .email("Invalid email")
              .required("Email is required"),
            mobile: yup
              .string()
              .phone("IN", true, "Invalid Mobile")
              .required("Mobile is required"),
            address1: yup
              .string()
              .min(5, "Atleast 5 characters required")
              .required("Address is required"),
            address2: yup.string(),
            city: yup
              .string()
              .required("City is required")
              .min("3", "Please enter valid city"),
            state: yup
              .string()
              .required("State is required")
              .min("3", "Please enter valid state"),
            pincode: yup
              .string()
              .required("Pincode is required")
              .min(6, "pincode must be 6 digits")
              .max(6, "pincode must be 6 digits"),
            rooms: yup.number().min(1).required("Number of rooms is required"),
            area: yup.number().min(100).required("Build up area is required"),
            // highmonth: yup
            //   .number()
            //   .min(1)
            //   .required("High consumption month is required"),
            highmonth: yup
              .array()
              .min(4, "Please choose four high months")
              .required("High consumption month is required"),
          })}
          onSubmit={(values, formik) => {
            // console.log(values);
            setPersonalInfo(values);
            setSteps(2);
          }}
        >
          {(formik) => {
            return (
              <div className="loginContainer">
                <div className="form-header">
                  <h1 className="primaryColor caption">Personal Information</h1>
                </div>
                {/* <Fade bottom> */}
                <div className="loginForm">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="form-inputs">
                          <label htmlFor="firstname">
                            First Name {required}
                          </label>
                          <input
                            type="text"
                            value={formik.values.firstname}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "firstname",
                                e.currentTarget.value.replace(/[^a-zA-Z ]/g, "")
                              )
                            }
                            onBlur={formik.handleBlur("firstname")}
                            id="firstname"
                            placeholder="Firstname"
                            className={
                              formik.touched.firstname &&
                              formik.errors.firstname
                                ? "form-control error-input"
                                : "form-control "
                            }
                          />

                          {formik.touched.firstname && formik.errors.firstname && (
                            <Fade bottom collapse opposite>
                              <div className="error-position">
                                <p className="error">
                                  {formik.errors.firstname}
                                </p>
                              </div>
                            </Fade>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-inputs">
                          <label htmlFor="lastname">Last Name {required}</label>
                          <input
                            type="text"
                            value={formik.values.lastname}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "lastname",
                                e.currentTarget.value.replace(/[^a-zA-Z ]/g, "")
                              )
                            }
                            onBlur={formik.handleBlur("lastname")}
                            id="lastname"
                            placeholder="Lastname"
                            className={
                              formik.touched.lastname && formik.errors.lastname
                                ? "form-control error-input"
                                : "form-control "
                            }
                          />

                          {formik.touched.lastname && formik.errors.lastname && (
                            <Fade bottom collapse opposite>
                              <div className="error-position">
                                <p className="error">
                                  {formik.errors.lastname}
                                </p>
                              </div>
                            </Fade>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-inputs">
                          <label htmlFor="email">Email {required}</label>
                          <input
                            type="email"
                            value={formik.values.email}
                            onChange={formik.handleChange("email")}
                            onBlur={formik.handleBlur("email")}
                            id="email"
                            placeholder="Email"
                            className={
                              formik.touched.email && formik.errors.email
                                ? "form-control error-input"
                                : "form-control "
                            }
                          />
                          {formik.touched.email && formik.errors.email && (
                            <Fade bottom collapse opposite>
                              <div className="error-position">
                                <p className="error">{formik.errors.email}</p>
                              </div>
                            </Fade>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-inputs">
                          <label htmlFor="mobile">Mobile {required}</label>
                          <input
                            type="text"
                            value={formik.values.mobile}
                            maxLength={10}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "mobile",
                                e.currentTarget.value
                                  .trim()
                                  .replace(/[^0-9]/g, "")
                              )
                            }
                            onBlur={formik.handleBlur("mobile")}
                            id="mobile"
                            placeholder="Mobile"
                            className={
                              formik.touched.mobile && formik.errors.mobile
                                ? "form-control error-input"
                                : "form-control "
                            }
                          />
                          {formik.touched.mobile && formik.errors.mobile && (
                            <Fade bottom collapse opposite>
                              <div className="error-position">
                                <p className="error">{formik.errors.mobile}</p>
                              </div>
                            </Fade>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-inputs">
                          <label htmlFor="address1">
                            Address Line 1 {required}
                          </label>
                          <input
                            type="text"
                            value={formik.values.address1}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "address1",
                                e.currentTarget.value.replace(
                                  /[^0-9a-zA-Z.-_, ]/g,
                                  ""
                                )
                              )
                            }
                            onBlur={formik.handleBlur("address1")}
                            id="address1"
                            placeholder="Address line 1"
                            className={
                              formik.touched.address1 && formik.errors.address1
                                ? "form-control error-input"
                                : "form-control "
                            }
                          />
                          {formik.touched.address1 && formik.errors.address1 && (
                            <Fade bottom collapse opposite>
                              <div className="error-position">
                                <p className="error">
                                  {formik.errors.address1}
                                </p>
                              </div>
                            </Fade>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-inputs">
                          <label htmlFor="address2">Address Line 2</label>
                          <input
                            type="text"
                            value={formik.values.address2}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "address2",
                                e.currentTarget.value.replace(
                                  /[^0-9a-zA-Z.-_, ]/g,
                                  ""
                                )
                              )
                            }
                            onBlur={formik.handleBlur("address2")}
                            id="address2"
                            placeholder="Address line 2"
                            className={
                              formik.touched.address2 && formik.errors.address2
                                ? "form-control error-input"
                                : "form-control "
                            }
                          />
                          {formik.touched.address2 && formik.errors.address2 && (
                            <Fade bottom collapse opposite>
                              <div className="error-position">
                                <p className="error">
                                  {formik.errors.address2}
                                </p>
                              </div>
                            </Fade>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-inputs">
                          <label htmlFor="city">City {required}</label>
                          <input
                            type="text"
                            value={formik.values.city}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "city",
                                e.currentTarget.value.replace(/[^a-zA-Z ]/g, "")
                              )
                            }
                            onBlur={formik.handleBlur("city")}
                            id="city"
                            placeholder="City"
                            className={
                              formik.touched.city && formik.errors.city
                                ? "form-control error-input"
                                : "form-control "
                            }
                          />
                          {formik.touched.city && formik.errors.city && (
                            <Fade bottom collapse opposite>
                              <div className="error-position">
                                <p className="error">{formik.errors.city}</p>
                              </div>
                            </Fade>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-inputs">
                          <label htmlFor="state">State {required}</label>
                          <input
                            type="text"
                            value={formik.values.state}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "state",
                                e.currentTarget.value.replace(/[^a-zA-Z ]/g, "")
                              )
                            }
                            onBlur={formik.handleBlur("state")}
                            id="state"
                            placeholder="State"
                            className={
                              formik.touched.state && formik.errors.state
                                ? "form-control error-input"
                                : "form-control "
                            }
                          />
                          {formik.touched.state && formik.errors.state && (
                            <Fade bottom collapse opposite>
                              <div className="error-position">
                                <p className="error">{formik.errors.state}</p>
                              </div>
                            </Fade>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-inputs">
                          <label htmlFor="pincode">Pincode {required}</label>
                          <input
                            type="text"
                            value={formik.values.pincode}
                            maxLength={6}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "pincode",
                                e.currentTarget.value.replace(/[^0-9]/g, "")
                              )
                            }
                            onBlur={formik.handleBlur("pincode")}
                            id="pincode"
                            placeholder="Pincode"
                            className={
                              formik.touched.pincode && formik.errors.pincode
                                ? "form-control error-input"
                                : "form-control "
                            }
                          />
                          {formik.touched.pincode && formik.errors.pincode && (
                            <Fade bottom collapse opposite>
                              <div className="error-position">
                                <p className="error">{formik.errors.pincode}</p>
                              </div>
                            </Fade>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-inputs">
                          <label htmlFor="rooms">
                            Built up area (in sq ft) {required}
                          </label>
                          <input
                            type="number"
                            value={formik.values.area}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "area",
                                e.currentTarget.value
                                  .trim()
                                  .replace(/[^0-9]/g, "")
                              )
                            }
                            onBlur={formik.handleBlur("area")}
                            id="area"
                            min={100}
                            placeholder="Built up area (in sq ft) "
                            className={
                              formik.touched.area && formik.errors.area
                                ? "form-control error-input"
                                : "form-control "
                            }
                          />

                          {formik.touched.area && formik.errors.area && (
                            <Fade bottom collapse opposite>
                              <div className="error-position">
                                <p className="error">{formik.errors.area}</p>
                              </div>
                            </Fade>
                          )}
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-inputs">
                          <label htmlFor="rooms">
                            No. of rooms (Living rooms, Dinning, Bed rooms){" "}
                            {required}
                          </label>
                          <input
                            type="number"
                            value={formik.values.rooms}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "rooms",
                                e.currentTarget.value
                                  .trim()
                                  .replace(/[^0-9]/g, "")
                              )
                            }
                            onBlur={formik.handleBlur("rooms")}
                            id="rooms"
                            min={1}
                            placeholder="Number of rooms (including Living rooms, Dinning,
                                Bed rooms)"
                            className={
                              formik.touched.rooms && formik.errors.rooms
                                ? "form-control error-input"
                                : "form-control "
                            }
                          />

                          {formik.touched.rooms && formik.errors.rooms && (
                            <Fade bottom collapse opposite>
                              <div className="error-position">
                                <p className="error">{formik.errors.rooms}</p>
                              </div>
                            </Fade>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="select-blabel--container">
                          <label htmlFor="highmonth" className="select-blabel">
                            Choose four months when your electricity consumption
                            is the highest {required}
                          </label>
                          <Select
                            options={monthOptions}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{
                              Option,
                            }}
                            maxMenuHeight={180}
                            onChange={(selected) => {
                              if (selected.length <= 4)
                                formik.setFieldValue("highmonth", selected);
                            }}
                            styles={{
                              control: (base, { data }) => ({
                                ...base,
                                borderColor:
                                  formik.touched.highmonth &&
                                  formik.errors.highmonth
                                    ? "red"
                                    : "#abaaaa",
                                padding: 2,
                              }),
                            }}
                            allowSelectAll={true}
                            value={formik.values.highmonth}
                            // menuIsOpen={
                            //   formik.values.highmonth?.length === 4
                            //     ? false
                            //     : undefined
                            // }
                            placeholder="High Months"
                            menuPlacement={"auto"}
                            isSearchable={false}
                          />
                          {formik.touched.highmonth && formik.errors.highmonth && (
                            <Fade bottom collapse opposite>
                              <div className="error-position">
                                <p className="error">
                                  {formik.errors.highmonth}
                                </p>
                              </div>
                            </Fade>
                          )}
                        </div>

                        {/* <div className="form-inputs">
                          <label htmlFor="highmonth">
                            Number of months with high consumption {required}
                          </label>
                          <input
                            type="number"
                            value={formik.values.highmonth}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "highmonth",
                                e.currentTarget.value.replace(/[^0-9]/g, "")
                              )
                            }
                            onBlur={formik.handleBlur("highmonth")}
                            id="highmonth"
                            min={1}
                            placeholder="Number of months with high consumption "
                            className={
                              formik.touched.highmonth &&
                              formik.errors.highmonth
                                ? "form-control error-input"
                                : "form-control "
                            }
                          />

                          {formik.touched.highmonth && formik.errors.highmonth && (
                            <Fade bottom collapse opposite>
                              <div className="error-position">
                                <p className="error">
                                  {formik.errors.highmonth}
                                </p>
                              </div>
                            </Fade>
                          )}
                        </div> */}
                      </div>
                    </div>

                    <div className="form-btn">
                      <button
                        className="btn btn-warning"
                        type="submit"
                        onClick={() => {
                          if (formik.errors) {
                            //       console.log(formik.errors);
                            const errors = Object.keys(formik.errors);
                            if (errors.length) {
                              const id = errors[0];
                              const area = document.getElementById(id);

                              area.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                                inline: "start",
                              });
                              area.focus();
                            }
                          } else formik.handleSubmit();
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </form>
                </div>
                {/* </Fade> */}
              </div>
            );
          }}
        </Formik>
      </>
    );
  });

  const AppliancesModal = React.memo(
    ({ show, onHide, close, setList, list, modify, setModify }) => {
      const [appTypes, setTypes] = useState([]);
      const [appliancesList, setAppList] = useState([]);
      const [wattsList, setWattsList] = useState([]);

      const formRef = useRef();

      const typeList = useCallback(async () => {
        try {
          const res = await axios.get(GLOBAL_URL + "/appliances/types", {
            headers: {
              "Content-Type": "application/json",
              Accept: "*",
            },
          });
          //     console.log("typeList", res);
          if (res.status === 200) {
            let options = [];
            res.data.data.map((td) => {
              return options.push({ label: td.app_type, value: td.id });
            });
            setTypes([...options, { label: "Any others ?", value: "Others" }]);
          }
        } catch (error) {
          console.log(error);
        }
      }, []);

      const getAppsList = useCallback(async (app_type_id) => {
        try {
          const res = await axios.get(GLOBAL_URL + "/products", {
            headers: {
              "Content-Type": "application/json",
              Accept: "*",
            },
            params: {
              app_type_id,
            },
          });
          //    console.log(res);
          if (res.status === 200) {
            let options = [];
            res.data.data.map((td) => {
              return options.push({
                label: td.appliances_name,
                value: td.id,
                watts: td.watts,
              });
            });
            setAppList(options);
          }
        } catch (error) {
          console.log(error);
        }
      }, []);
      // console.log("modify", modify, appliancesList);
      useEffect(() => {
        //   console.log("model mounted");
        typeList();

        if (modify.index !== null) {
          //      console.log("first", modify.data.app_type.value);
          getAppsList(modify.data.app_type.value);
        }
      }, []);

      const initialValuesC =
        modify.index !== null
          ? {
              app_type: modify.data.app_type,
              appliances_name: modify.data.appliances_name,
              other_appliances_name: modify.data.other_appliances_name,
              other_watts: modify.data.other_watts,
              watts: modify.data.watts,
              quantity: modify.data.quantity,
              day_peak_hours: modify.data.day_peak_hours,
              day_nonpeak_hours: modify.data.day_nonpeak_hours,
            }
          : {
              app_type: null,
              appliances_name: null,
              other_appliances_name: "",
              other_watts: "",
              watts: null,
              quantity: "",
              day_peak_hours: "",
              day_nonpeak_hours: "",
            };
      const deleteItem = () => {
        if (modify.index !== null) {
          let data = [...list];
          data.splice(modify.index, 1);

          setList([...data]);
          setModify({
            index: null,
            data: null,
          });
          close();
        } else {
          close();
        }
      };
      // console.log("listttttt", list);
      return (
        <Modal
          show={show}
          onHide={() => {
            setAppList([]);
            setWattsList([]);
            onHide();
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Electrical Consumption On Appliances
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={initialValuesC}
              enableReinitialize
              validateOnChange
              validationSchema={yup.object({
                app_type: yup
                  .object()
                  .required("Appliance type is required")
                  .nullable(),
                appliances_name: yup
                  .object()
                  .nullable()
                  .when("app_type", (app_type) => {
                    //   console.log("app_type", app_type);
                    if (app_type === null || app_type.value !== "Others")
                      return yup
                        .object()
                        .required("Appliances  is required")
                        .nullable();
                  }),

                watts: yup
                  .object()
                  .nullable()
                  .when("app_type", (app_type) => {
                    //     console.log("app_type", app_type);
                    if (app_type === null || app_type.value !== "Others")
                      return yup
                        .object()
                        .required("watts  is required")
                        .nullable();
                  }),

                quantity: yup
                  .number()
                  .required("Quantity  is required")
                  .min(1, "Quantity must be atleast 1 or above"),
                day_peak_hours: yup
                  .number()
                  .required("Peak hours  is required")
                  .max(24, "Should not exceed 24 hours"),
                day_nonpeak_hours: yup
                  .number()
                  .required("off-peak hours  is required")
                  .max(24, "Should not exceed 24 hours"),
                other_appliances_name: yup
                  .string()
                  .when("app_type", (app_type) => {
                    //   console.log("app_type", app_type);
                    if (app_type !== null && app_type.value === "Others")
                      return yup.string().required("Appliances  is required");
                  }),
                other_watts: yup.string().when("app_type", (app_type) => {
                  if (app_type !== null && app_type.value === "Others")
                    return yup.number().required("Appliances  is required");
                }),
              })}
              onSubmit={(values, formik) => {
                //   console.log(values);

                if (modify.index !== null) {
                  let data = [...list];
                  data[modify.index] = values;
                  //   console.log(data);
                  setList([...data]);
                  setModify({
                    index: null,
                    data: null,
                  });
                  setAppList([]);
                  setWattsList([]);
                  close();
                } else {
                  setList([...list, values]);
                  setAppList([]);
                  setWattsList([]);
                  close();
                }
              }}
              innerRef={formRef}
            >
              {(formik) => {
                //  console.log("formik.errors", formik.errors);
                return (
                  <form onSubmit={formik.handleSubmit}>
                    {appTypes.length ? (
                      <>
                        <div className="row">
                          <div className="col-12 ">
                            <div className="modal-form-inputs">
                              <label htmlFor="app_type">
                                Appliances Type {required}
                              </label>
                              <Select
                                options={appTypes}
                                value={formik.values.app_type}
                                onChange={(val) => {
                                  formik.setFieldValue("app_type", val);
                                  formik.setFieldValue("appliances_name", null);
                                  formik.setFieldValue("watts", null);
                                  getAppsList(val.value);
                                }}
                                isSearchable={false}
                                onMenuOpen={() => formik.handleBlur("app_type")}
                                id="app_type"
                                styles={{
                                  control: (base, { data }) => ({
                                    ...base,
                                    borderColor:
                                      formik.touched.app_type &&
                                      formik.errors.app_type
                                        ? "#d60e0e"
                                        : "#abaaaa",
                                    padding: 2,
                                  }),
                                }}
                              />

                              {formik.touched.app_type &&
                                formik.errors.app_type && (
                                  <Fade bottom collapse opposite>
                                    <div className="error-position">
                                      <p className="error">
                                        {formik.errors.app_type}
                                      </p>
                                    </div>
                                  </Fade>
                                )}
                            </div>
                          </div>
                          {formik.values.app_type !== null &&
                          formik.values.app_type.value === "Others" ? (
                            <>
                              <div className="col-12 ">
                                <div className="modal-form-inputs">
                                  <label htmlFor="other_appliances_name">
                                    Appliances Name {required}
                                  </label>
                                  <input
                                    type="text"
                                    id="other_appliances_name"
                                    // onChange={formik.handleChange(
                                    //   "other_appliances_name"
                                    // )}
                                    placeholder="Appliances name"
                                    onChange={(e) =>
                                      formik.setFieldValue(
                                        "other_appliances_name",
                                        e.currentTarget.value.replace(
                                          /[^0-9a-zA-Z- ]/g,
                                          ""
                                        )
                                      )
                                    }
                                    onBlur={formik.handleBlur(
                                      "other_appliances_name"
                                    )}
                                    value={formik.values.other_appliances_name}
                                    style={{
                                      border:
                                        formik.touched.other_appliances_name &&
                                        formik.errors.other_appliances_name
                                          ? "1px solid #d60e0e"
                                          : "1px solid #abaaaa",
                                    }}
                                  />
                                  {formik.touched.other_appliances_name &&
                                    formik.errors.other_appliances_name && (
                                      <Fade bottom collapse opposite>
                                        <div className="error-position">
                                          <p className="error">
                                            {
                                              formik.errors
                                                .other_appliances_name
                                            }
                                          </p>
                                        </div>
                                      </Fade>
                                    )}
                                </div>
                              </div>
                              <div className="col-12 ">
                                <div className="modal-form-inputs">
                                  <label htmlFor="quantity">
                                    Quantity {required}
                                  </label>
                                  <input
                                    type="number"
                                    id="quantity"
                                    min={1}
                                    placeholder="quantity"
                                    style={{
                                      border:
                                        formik.touched.quantity &&
                                        formik.errors.quantity
                                          ? "1px solid #d60e0e"
                                          : "1px solid #abaaaa",
                                    }}
                                    onChange={formik.handleChange("quantity")}
                                    value={formik.values.quantity}
                                    onBlur={formik.handleBlur("quantity")}
                                  />
                                  {formik.touched.quantity &&
                                    formik.errors.quantity && (
                                      <Fade bottom collapse opposite>
                                        <div className="error-position">
                                          <p className="error">
                                            {formik.errors.quantity}
                                          </p>
                                        </div>
                                      </Fade>
                                    )}
                                </div>
                              </div>

                              <div className="col-12 ">
                                <div className="modal-form-inputs">
                                  <label htmlFor="other_watts">
                                    Watts {required}
                                  </label>
                                  <input
                                    type="number"
                                    id="other_watts"
                                    onChange={formik.handleChange(
                                      "other_watts"
                                    )}
                                    placeholder="watts"
                                    min={1}
                                    onBlur={formik.handleBlur("other_watts")}
                                    value={formik.values.other_watts}
                                    style={{
                                      border:
                                        formik.touched.other_watts &&
                                        formik.errors.other_watts
                                          ? "1px solid #d60e0e"
                                          : "1px solid #abaaaa",
                                    }}
                                  />
                                  {formik.touched.other_watts &&
                                    formik.errors.other_watts && (
                                      <Fade bottom collapse opposite>
                                        <div className="error-position">
                                          <p className="error">
                                            {formik.errors.other_watts}
                                          </p>
                                        </div>
                                      </Fade>
                                    )}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-12 ">
                                <div className="modal-form-inputs">
                                  <label htmlFor="appliances_name">
                                    Appliances {required}
                                  </label>
                                  <Select
                                    options={appliancesList}
                                    isLoading={!appliancesList.length}
                                    isDisabled={!appliancesList.length}
                                    value={formik.values.appliances_name}
                                    isSearchable={false}
                                    onChange={(val) => {
                                      //    console.log("val", { val });
                                      formik.setFieldValue(
                                        "appliances_name",
                                        val
                                      );
                                      formik.setFieldValue("watts", null);
                                      setWattsList(val.watts);
                                    }}
                                    onMenuOpen={() =>
                                      formik.handleBlur("appliances_name")
                                    }
                                    id="appliances_name"
                                    styles={{
                                      control: (base, { data }) => ({
                                        ...base,
                                        borderColor:
                                          formik.touched.appliances_name &&
                                          formik.errors.appliances_name
                                            ? "#d60e0e"
                                            : "#abaaaa",
                                        padding: 2,
                                      }),
                                    }}
                                  />
                                  {formik.touched.appliances_name &&
                                    formik.errors.appliances_name && (
                                      <Fade bottom collapse opposite>
                                        <div className="error-position">
                                          <p className="error">
                                            {formik.errors.appliances_name}
                                          </p>
                                        </div>
                                      </Fade>
                                    )}
                                </div>
                              </div>
                              <div className="col-12 ">
                                <div className="modal-form-inputs">
                                  <label htmlFor="quantity">
                                    Quantity {required}
                                  </label>
                                  <input
                                    type="number"
                                    id="quantity"
                                    min={1}
                                    placeholder="quantity"
                                    onChange={formik.handleChange("quantity")}
                                    value={formik.values.quantity}
                                    onBlur={formik.handleBlur("quantity")}
                                    style={{
                                      border:
                                        formik.touched.quantity &&
                                        formik.errors.quantity
                                          ? "1px solid #d60e0e"
                                          : "1px solid #abaaaa",
                                    }}
                                  />
                                  {formik.touched.quantity &&
                                    formik.errors.quantity && (
                                      <Fade bottom collapse opposite>
                                        <div className="error-position">
                                          <p className="error">
                                            {formik.errors.quantity}
                                          </p>
                                        </div>
                                      </Fade>
                                    )}
                                </div>
                              </div>
                              <div className="col-12 ">
                                <div className="modal-form-inputs">
                                  <label htmlFor="watts">
                                    Watts {required}
                                  </label>
                                  <Select
                                    options={wattsList.map((td) => {
                                      return { label: td, value: td };
                                    })}
                                    isSearchable={false}
                                    id="watts"
                                    isLoading={!wattsList.length}
                                    isDisabled={!wattsList.length}
                                    value={formik.values.watts}
                                    onMenuOpen={() =>
                                      formik.handleBlur("watts")
                                    }
                                    onChange={(val) => {
                                      //   console.log("val", { val });
                                      formik.setFieldValue("watts", val);
                                    }}
                                    styles={{
                                      control: (base, { data }) => ({
                                        ...base,
                                        borderColor:
                                          formik.touched.watts &&
                                          formik.errors.watts
                                            ? "#d60e0e"
                                            : "#abaaaa",
                                        padding: 2,
                                      }),
                                    }}
                                  />
                                  {formik.touched.watts && formik.errors.watts && (
                                    <Fade bottom collapse opposite>
                                      <div className="error-position">
                                        <p className="error">
                                          {formik.errors.watts}
                                        </p>
                                      </div>
                                    </Fade>
                                  )}
                                </div>
                              </div>
                            </>
                          )}

                          <div className="col-12 ">
                            <div className="modal-form-inputs">
                              <label htmlFor="day_peak_hours">
                                Estimated usage in a day during peak months (in
                                hours) (per device) {required}{" "}
                                <button
                                  onClick={() => {
                                    let convert = parseFloat(
                                      (
                                        formik.values.day_peak_hours / 60
                                      ).toFixed(2)
                                    );
                                    formik.setFieldValue(
                                      "day_peak_hours",
                                      convert
                                    );
                                  }}
                                  type="button"
                                  title="Convert minutes to hours eg: enter 10 and click convert 10 minutes to hours"
                                  className="btn btn-success"
                                  style={{
                                    padding: "5px",
                                    fontSize: "9px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  convert min to hr
                                </button>
                              </label>
                              <input
                                type="number"
                                id="day_peak_hours"
                                // min={0}
                                // max={24}
                                onChange={formik.handleChange("day_peak_hours")}
                                value={formik.values.day_peak_hours}
                                onBlur={formik.handleBlur("day_peak_hours")}
                                placeholder={"Enter usage in hours"}
                                style={{
                                  border:
                                    formik.touched.day_peak_hours &&
                                    formik.errors.day_peak_hours
                                      ? "1px solid #d60e0e"
                                      : "1px solid #abaaaa",
                                }}
                              />
                              {formik.touched.day_peak_hours &&
                                formik.errors.day_peak_hours && (
                                  <Fade bottom collapse opposite>
                                    <div className="error-position">
                                      <p className="error">
                                        {formik.errors.day_peak_hours}
                                      </p>
                                    </div>
                                  </Fade>
                                )}
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="modal-form-inputs">
                              <label htmlFor="day_nonpeak_hours">
                                Estimated usage in a day during off-peak months
                                (in hours) (per device) {required}
                                <button
                                  onClick={() => {
                                    let convert = parseFloat(
                                      (
                                        formik.values.day_nonpeak_hours / 60
                                      ).toFixed(2)
                                    );
                                    formik.setFieldValue(
                                      "day_nonpeak_hours",
                                      convert
                                    );
                                  }}
                                  type="button"
                                  className="btn btn-success"
                                  title="Convert minutes to hours eg: enter 10 and click convert 10 minutes to hours"
                                  style={{
                                    padding: "5px",
                                    fontSize: "9px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  convert min to hr
                                </button>
                              </label>
                              <input
                                type="number"
                                id="day_nonpeak_hours"
                                onChange={formik.handleChange(
                                  "day_nonpeak_hours"
                                )}
                                placeholder={"Enter usage in hours"}
                                value={formik.values.day_nonpeak_hours}
                                onBlur={formik.handleBlur("day_nonpeak_hours")}
                                style={{
                                  border:
                                    formik.touched.day_nonpeak_hours &&
                                    formik.errors.day_nonpeak_hours
                                      ? "1px solid #d60e0e"
                                      : "1px solid #abaaaa",
                                }}
                              />
                              {formik.touched.day_nonpeak_hours &&
                                formik.errors.day_nonpeak_hours && (
                                  <Fade bottom collapse opposite>
                                    <div className="error-position">
                                      <p className="error">
                                        {formik.errors.day_nonpeak_hours}
                                      </p>
                                    </div>
                                  </Fade>
                                )}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </form>
                );
              }}
            </Formik>
          </Modal.Body>
          <Modal.Footer>
            {modify.index !== null ? (
              <>
                <button
                  onClick={deleteItem}
                  className="btn close"
                  type="button"
                >
                  Delete
                </button>
                <button
                  className="btn new btn-success"
                  type="button"
                  onClick={() => {
                    if (formRef.current) {
                      formRef.current.handleSubmit();
                    }
                  }}
                >
                  Modify
                </button>
              </>
            ) : (
              <>
                <button onClick={onHide} className="btn close" type="button">
                  Close
                </button>
                <button
                  className="btn new btn-success"
                  type="button"
                  onClick={() => {
                    if (formRef.current) {
                      formRef.current.handleSubmit();
                    }
                  }}
                >
                  Add
                </button>
              </>
            )}
          </Modal.Footer>
        </Modal>
      );
    }
  );

  const Consumption = React.memo(() => {
    const [modalShow, setModalShow] = React.useState(false);
    const [consumptionAppList, setConsumptionAppList] = useState([]);
    const [editData, setEditData] = useState({
      index: null,
      data: null,
    });
    const [popShow, setPop] = useState(true);
    const PopModal = useCallback((props) => {
      return (
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="deleteModal-header">
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="deleteModal-headerText"
            >
              Note
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="deleteModal-body">
            <h5 className="mb-1 note-text">
              Please consider the selected months as peak months for your
              electricity consumption and the other months as off-peak.
            </h5>
            <h6 className="deleteModal-bodyText note-sub">
              {personalInfo.highmonth
                .map((td) => td.value)
                .toString()
                .replace(/[,]+/g, " , ")}
            </h6>
          </Modal.Body>

          <Modal.Footer>
            {/* <Button
              variant="contained"
              id="modalDeleteBtn"
              className={"modalDeleteBtn"}
              onClick={() => deleteTypes(data.index)}
            >
              Delete
            </Button> */}
            <Button
              variant="contained"
              id="modalcloseBtn"
              className={"modalcloseBtn"}
              onClick={props.onHide}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      );
    });
    useEffect(() => {
      setConsumptionAppList(consumptionItems);
    }, []);
    const annualConsumption = (qty, watts, onpeak, offpeak, highmonth) => {
      //  console.log({ qty, watts, onpeak, offpeak, highmonth });
      const off =
        Number(qty) *
        Number(watts) *
        Number(onpeak) *
        Number(highmonth.length) *
        30;
      const on =
        Number(qty) *
        Number(watts) *
        Number(offpeak) *
        (365 - Number(highmonth.length) * 30);
      const total = parseFloat(((off + on) / 1000).toFixed(2));
      return total;
    };

    const initialValuesC = {
      rooms: "",
      area: "",
      highmonth: "",
    };

    console.log("component two renderded", editData);
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={initialValuesC}
          validateOnChange
          validateOnBlur
          validationSchema={yup.object({
            rooms: yup.number().min(1),
            area: yup.number().min(100),
            highmonth: yup.number().min(1),
          })}
          onSubmit={(values, formik) => {
            //  console.log(values);
            // setConsumptionInfo(values);
            setConsumptionItems([...consumptionAppList]);
            setSteps(3);
          }}
        >
          {(formik) => {
            return (
              <div className="loginContainer" id="consumption">
                <AppliancesModal
                  show={modalShow}
                  onHide={() => {
                    setModalShow(false);
                    setEditData({
                      index: null,
                      data: null,
                    });
                  }}
                  close={setModalShow}
                  setList={setConsumptionAppList}
                  list={consumptionAppList}
                  modify={editData}
                  setModify={setEditData}
                />
                <PopModal show={popShow} onHide={() => setPop(false)} />
                <div className="form-header">
                  <h1 className="primaryColor caption">
                    Consumptions Information
                  </h1>
                </div>
                {/* <Fade bottom> */}
                <div className="loginForm">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        {/* <h1 className="primaryColor subcaption">
                          Enter your Electrical consumption to calculate
                        </h1> */}

                        <div className="table-responsive table-container">
                          <table className="table table-hover table-bordered">
                            <thead className="table-dark">
                              <tr>
                                <th scope="col">Type</th>
                                <th scope="col">Electrical Appliances</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Watts</th>
                                <th scope="col">
                                  Estimated usage in a day
                                  <br />- During peak months(in hours)
                                </th>
                                <th scope="col">
                                  Estimated usage in a day <br />- During
                                  off-peak months(in hours)
                                </th>
                                <th scope="col">
                                  Annual consumption
                                  <br /> (in Units)
                                </th>
                                <th scope="col" colSpan={2}>
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {consumptionAppList.length ? (
                                consumptionAppList.map((cd, i) => {
                                  return (
                                    <>
                                      <tr key={i}>
                                        <td>{cd.app_type.label}</td>
                                        <td>
                                          {cd.app_type.label === "Any others ?"
                                            ? cd.other_appliances_name
                                            : cd.appliances_name.label}
                                        </td>
                                        <td>{cd.quantity}</td>
                                        <td>
                                          {cd.app_type.label === "Any others ?"
                                            ? cd.other_watts
                                            : cd.watts.value}
                                        </td>

                                        <td>{cd.day_peak_hours}</td>
                                        <td>{cd.day_nonpeak_hours}</td>
                                        <td>
                                          {annualConsumption(
                                            cd.quantity,
                                            cd.app_type.label === "Any others ?"
                                              ? cd.other_watts
                                              : cd.watts.label,
                                            cd.day_peak_hours,
                                            cd.day_nonpeak_hours,
                                            personalInfo.highmonth
                                          )}
                                        </td>
                                        <td colSpan={2}>
                                          <IconContext.Provider
                                            value={{
                                              color: "#e27e2f",

                                              size: "32px",
                                            }}
                                          >
                                            <div>
                                              <AiFillEdit
                                                onClick={() => {
                                                  setEditData({
                                                    index: i,
                                                    data: cd,
                                                  });
                                                  setModalShow(true);
                                                }}
                                              />
                                            </div>
                                          </IconContext.Provider>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={8}>No Items added</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="form-btn-row">
                      <button
                        className="btn btn-secondary prev"
                        type="button"
                        onClick={() => {
                          setConsumptionInfo(formik.values);
                          setConsumptionItems([...consumptionAppList]);
                          setSteps(1);
                        }}
                      >
                        Previous
                      </button>
                      <button
                        className="btn btn-lg btn-success new"
                        type="button"
                        onClick={() => {
                          setModalShow(true);
                        }}
                      >
                        Add New
                      </button>
                      <button
                        className="btn btn-warning next"
                        type="submit"
                        disabled={consumptionAppList.length === 0}
                        onClick={() => {
                          if (formik.errors) {
                            const errors = Object.keys(formik.errors);
                            if (errors.length) {
                              const id = errors[0];
                              const area = document.getElementById(id);

                              area.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                                inline: "start",
                              });
                              area.focus();
                            }
                          } else formik.handleSubmit();
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </form>
                </div>
                {/* </Fade> */}
              </div>
            );
          }}
        </Formik>
      </>
    );
  });

  const Questionaire = React.memo(() => {
    const [value, setValue] = React.useState(1);

    const [questionaireList, setQuestionaire] = useState([]);
    const [generating, setGenerating] = useState(false);

    const getQuestionaire = useCallback(async () => {
      try {
        const res = await axios.get(GLOBAL_URL + "/questionaire/", {
          headers: {
            "Content-Type": "application/json",
            Accept: "*",
          },
        });

        if (res.status === 200) {
          setQuestionaire(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    }, []);

    useEffect(() => {
      if (GlobalQuestionaire.length) {
        setQuestionaire(GlobalQuestionaire);
      } else {
        getQuestionaire();
      }
    }, []);

    function setChecked(i, val) {
      let data = questionaireList;
      data[i].answer = val;

      setQuestionaire([...data]);
    }

    const handleChange = (i, val) => {
      let data = questionaireList;
      data[i].answer = val;
      // console.log(data);
      setQuestionaire([...data]);
    };
    async function generateReport() {
      try {
        const ip = await axios.get("https://api.ipify.org");
        // const ip = 111111;
        let higherMonths = personalInfo.highmonth.map((td) => td.value);
        let data = {
          personal: {
            ...personalInfo,
            highmonth: personalInfo.highmonth.length,
            higherMonths: higherMonths.toString(),
          },
          // consumption: consumptionInfo,
          consumptionItems: consumptionItems,
          questionaire: questionaireList,
          client_ip: ip.data,
        };
        setGenerating(true);
        const result = await axios.post(GLOBAL_URL + "/report/generate", data, {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        });

        if (result.status === 200) {
          const url = window.URL.createObjectURL(new Blob([result.data]));
          let data = {
            url,
          };
          setGlobalQuestionaire([...questionaireList]);

          setGenerating(false);
          setBlob(data);
          return setSteps(4);
        }
      } catch (error) {
        toaster.notify("Report Generation Failed", "error");
        setGenerating(false);
        console.log(error);
      }
    }

    return (
      <>
        <div className="loginContainer">
          <div className="form-header">
            <h1 className="primaryColor caption">Questionnaire</h1>
          </div>
          {/* <Fade bottom > */}
          <div className="loginForm">
            {questionaireList.length ? (
              <>
                <div className="row align-items-center justify-content-between">
                  {questionaireList.map((qd, qi) => {
                    return (
                      <>
                        <div
                          className="col-12 col-md-8 col-lg-9 question-margin"
                          key={qi + "A"}
                        >
                          <div className="d-flex flex-row align-items-center">
                            <FcIdea size={30} />
                            <span className="question">{qd.question}</span>
                          </div>
                        </div>
                        <div
                          className="col-12  col-md-4 col-lg-3 question-margin"
                          key={qi + "B"}
                        >
                          {/* <Switch
                              color="warning"
                           
                            /> */}
                          <FormControl>
                            {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={qd.answer}
                              onChange={(e) => {
                                handleChange(qi, e.target.value);
                              }}
                              row
                            >
                              <FormControlLabel
                                value={1}
                                control={<Radio color="success" />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value={0}
                                control={<Radio color="success" />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                          {/* <IOSSwitch
                            checked={qd.answer}
                            onChange={(e) => {
                              setChecked(qi, e.target.checked);
                            }}
                          /> */}
                        </div>
                      </>
                    );
                  })}
                  <div className="col-12">
                    <div className="form-btn-row">
                      <button
                        className="btn btn-lg btn-secondary prev"
                        type="button"
                        onClick={() => {
                          setGlobalQuestionaire([...questionaireList]);
                          setSteps(2);
                        }}
                      >
                        Previous
                      </button>
                      <button
                        className="btn btn-lg  btn-success new"
                        type="button"
                        onClick={generateReport}
                        disabled={generating}
                      >
                        {generating ? "loading ..." : "Generate"}
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="text-center w-100 ">
                <img
                  src={quesIcon}
                  alt="Questionnaire"
                  height={300}
                  width={300}
                  className="m-auto mb-1"
                />
                No Questionaire Found !!!
              </div>
            )}
          </div>
          {/* </Fade> */}
        </div>
      </>
    );
  });

  // useEffect(() => {
  //   if ("geolocation" in navigator) {
  //     console.log("Available");
  //     navigator.geolocation.getCurrentPosition(
  //       function (position) {
  //         console.log("Latitude is :", position.coords.latitude);
  //         console.log("Longitude is :", position.coords.longitude);
  //       },
  //       function (error) {
  //         console.error("Error Code = " + error.code + " - " + error.message);
  //       }
  //     );
  //   } else {
  //     console.log("Not Available");
  //   }
  // }, []);

  return (
    <>
      <section className="main-wrapper home-wrapper">
        <div className="d-flex  flex-container ">
          <div className="sidebar-container bgColor ">
            <div className="bg-white">
              <img src={logo} alt="cag-logo" className="img-fluid" />
            </div>
            <div>
              <div className="step-wizard">
                <div className="line"></div>
                <div className={step === 1 ? "step-active" : "step"}>1</div>
                <div className={step === 2 ? "step-active" : "step"}>2</div>
                <div className={step === 3 ? "step-active" : "step"}>3</div>
                <div className={step === 4 ? "step-active" : "step"}>4</div>
              </div>

              <div className="step-content">
                <div className="icons">
                  {step === 1 && <FaInfoCircle size={60} />}
                  {step === 2 && <MdElectricalServices size={60} />}
                  {step === 3 && <FaNotesMedical size={60} />}
                  {step === 4 && <TbReportAnalytics size={60} />}
                  <h2>
                    {step === 1
                      ? "About You"
                      : step === 2
                      ? "Consumption Input"
                      : step === 3
                      ? "Questionnaire"
                      : "Energy Audit Report"}
                  </h2>
                  <p>
                    {step === 4
                      ? "This energy audit report presents your household energy consumption patterns and makes suitable recommendations to conserve energy and promote energy efficiency in your household."
                      : "Fill in your details to help us provide you with an energy audit report based on your information/usage."}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {step === 1 ? (
            <Personal />
          ) : step === 2 ? (
            <Consumption />
          ) : step === 3 ? (
            <Questionaire />
          ) : (
            <PdfViewer
              pdfData={pdfBlob}
              fname={personalInfo.firstname}
              previous={setSteps}
            />
          )}
        </div>
      </section>
    </>
  );
}

export default Tool;
