import React, { useEffect, useState, useContext, useCallback } from "react";

import "../assets/css/apps.css";

import { Button } from "@mui/material";

import { MdKeyboardBackspace } from "react-icons/md";
import { IconContext } from "react-icons";

import { Formik } from "formik";
import * as yup from "yup";

import { CONFIG, GLOBAL_URL_ADMIN } from "../config";
import axios from "axios";
import { ToastContext } from "../Context/ToastContext";
import { Modal } from "react-bootstrap";
import Select from "react-select";

const required = <span style={{ color: "red" }}>*</span>;

const EditAppliances = ({ close, data }) => {
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [appsTypesList, setTypeList] = useState([]);

  const toaster = useContext(ToastContext);

  const ConfirmModal = useCallback((props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="deleteModal-header">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="deleteModal-headerText"
          >
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="deleteModal-body">
          <h4 className="deleteModal-bodyText">
            Do you want to Delete Appliance{" "}
            {data.edit ? data.data.appliance_name : null} ?
          </h4>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="contained"
            id="modalDeleteBtn"
            className={"modalDeleteBtn"}
            onClick={() => deleteTypes(data.index)}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            id="modalcloseBtn"
            className={"modalcloseBtn"}
            onClick={props.onHide}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  });

  async function deleteTypes(appliances_id) {
    try {
      let URL = GLOBAL_URL_ADMIN + "/products/delete";
      let VAL = { appliances_id };

      const req = await axios.post(URL, VAL, CONFIG());

      if (req.status === 200) {
        toaster.notify("Appliance has been removed", "success");
        setModalShow(false);
        close({
          state: false,
          data: null,
          edit: false,
          index: "",
        });
      }
    } catch (error) {
      console.log(error.response);
      if (error.response.status === 401) {
        toaster.notify("Unauthorised Access", "error");
      } else {
        toaster.notify("Failed to Remove", "error");
      }
    }
  }

  const typeList = useCallback(async () => {
    try {
      const res = await axios.get(
        GLOBAL_URL_ADMIN + "/appliances/types",
        CONFIG()
      );
      // console.log(res);
      if (res.status === 200) {
        let options = [];
        res.data.data.map((td) => {
          return options.push({ label: td.app_type, value: td.id });
        });
        setTypeList(options);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    typeList();
  }, []);
  const initialVal = data.edit
    ? {
        app_type: {
          label: data.data.app_type,
          value: data.data.app_type_id,
        },

        appliance_name: data.data.appliances_name,

        watts: data.data.watts.toString(),
      }
    : {
        app_type: null,

        appliance_name: "",

        watts: "",
      };

  return (
    <>
      <ConfirmModal show={modalShow} onHide={() => setModalShow(false)} />
      <div className="w-100 mb-5">
        <div className="row align-items-center mb-3">
          <div className="col-12">
            <div className="d-flex flex-row align-items-center  justify-content-start">
              <div
                className="mr-3"
                style={{ cursor: "pointer" }}
                onClick={() => close(false)}
              >
                <IconContext.Provider
                  value={{
                    size: "40px",
                    color: "#6a707e",
                  }}
                >
                  <MdKeyboardBackspace />
                </IconContext.Provider>
              </div>

              <h4 className="header-text " style={{ marginLeft: "20px" }}>
                {data.edit ? "Modify Appliances" : "Create Appliances"}
              </h4>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="loader-position">loading...</div>
        ) : (
          <>
            <div className="card-container">
              <Formik
                initialValues={initialVal}
                enableReinitialize
                validateOnChange={true}
                validateOnBlur={true}
                validationSchema={yup.object({
                  app_type: yup
                    .object()
                    .required("Appliances type is required")
                    .nullable(),
                  appliance_name: yup
                    .string()
                    .required("Appliance_name is required"),
                  watts: yup.string().required("Watts is required"),
                })}
                onSubmit={async (values) => {
                  // console.log({ values });
                  try {
                    let URL = GLOBAL_URL_ADMIN + "/products/add";
                    let VAL = {
                      app_type: values.app_type.label,
                      appliances_name: values.appliance_name,
                      app_type_id: values.app_type.value,
                      watts: values.watts,
                    };
                    if (data.edit) {
                      URL = GLOBAL_URL_ADMIN + "/products/update";
                      VAL = {
                        app_type: values.app_type.label,
                        appliances_name: values.appliance_name,
                        app_type_id: values.app_type.value,
                        watts: values.watts,
                        appliances_id: data.index,
                      };
                    }

                    const req = await axios.post(URL, VAL, CONFIG());

                    if (req.status === 200) {
                      toaster.notify(
                        data.edit
                          ? "Appliance has been Updated"
                          : "Appliance has been added",
                        "success"
                      );
                      close({
                        state: false,
                        data: null,
                        edit: false,
                        index: "",
                      });
                    }
                  } catch (error) {
                    console.log(error.response);
                    if (error.response.status === 401) {
                      toaster.notify("Unauthorised Access", "error");
                    } else {
                      toaster.notify(
                        data.edit
                          ? "Failed to update Appliance"
                          : "Failed to add Appliance",
                        "error"
                      );
                    }
                  }
                }}
              >
                {(formik) => {
                  return (
                    <>
                      <div
                        className="form-group mb-3"
                        style={{ position: "relative" }}
                        id="appliance_name_c"
                      >
                        <label htmlFor="status" className="create-label">
                          Appliance Name {required}
                        </label>
                        <input
                          value={formik.values.appliance_name}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "appliance_name",
                              e.currentTarget.value.replace(
                                /[^a-zA-Z0-9()/ ]/g,
                                ""
                              )
                            );
                          }}
                          className="form-control addInput"
                          placeholder="Appliance name"
                          id="appliance_name"
                          style={{
                            border:
                              formik.touched.appliance_name &&
                              formik.errors.appliance_name
                                ? "1px solid red"
                                : "1px solid #B3CDFF80",
                          }}
                        />

                        {formik.touched.appliance_name &&
                          formik.errors.appliance_name && (
                            <div className="tariff-error-position">
                              <p>{formik.errors.appliance_name}</p>
                            </div>
                          )}
                      </div>
                      <div
                        className="form-group mb-3"
                        style={{ position: "relative" }}
                        id="app_type_c"
                      >
                        <label htmlFor="status" className="create-label">
                          Appliance Type {required}
                        </label>
                        <Select
                          options={appsTypesList}
                          onChange={(p) => {
                            formik.setFieldValue("app_type", p);
                          }}
                          value={formik.values.app_type}
                          isSearchable={false}
                          styles={{
                            container: (provided, state) => ({
                              ...provided,
                              border:
                                formik.touched.app_type &&
                                formik.errors.app_type
                                  ? "1px solid red"
                                  : "1px solid #B3CDFF80",
                            }),
                          }}
                        />

                        {formik.touched.app_type && formik.errors.app_type && (
                          <div className="tariff-error-position">
                            <p>{formik.errors.app_type}</p>
                          </div>
                        )}
                      </div>

                      <div
                        className="form-group mb-3"
                        style={{ position: "relative" }}
                        id="form-wrapper"
                      >
                        <label htmlFor="watts" className="create-label">
                          Watts {required}
                        </label>
                        <input
                          value={formik.values.watts}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "watts",
                              e.currentTarget.value.replace(/[^0-9,.]/g, "")
                            );
                          }}
                          title="Enter watts separated by comma"
                          className="form-control addInput"
                          placeholder="eg: 100 , 200 , 300"
                          id="watts"
                          style={{
                            border:
                              formik.touched.watts && formik.errors.watts
                                ? "1px solid red"
                                : "1px solid #B3CDFF80",
                          }}
                        />

                        {formik.touched.watts && formik.errors.watts && (
                          <div className="tariff-error-position">
                            <p>{formik.errors.watts}</p>
                          </div>
                        )}
                      </div>

                      <div className="d-flex flex-row align-items-center justify-content-end mt-4 pt-3">
                        {data.edit ? (
                          <Button
                            variant="contained"
                            id="deleteBtn"
                            className={"deleteBtn"}
                            onClick={() => setModalShow(true)}
                            // disabled={true}
                          >
                            Delete
                          </Button>
                        ) : null}
                        <Button
                          variant="contained"
                          id="createBtn"
                          className={"createBtn"}
                          onClick={formik.handleSubmit}
                        >
                          {data.edit ? "Update" : "Add"}
                        </Button>
                      </div>
                    </>
                  );
                }}
              </Formik>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(EditAppliances);
