import React, { useEffect, useState, useContext, useCallback } from "react";

import "../assets/css/apps.css";

import { Button } from "@mui/material";

import { MdKeyboardBackspace } from "react-icons/md";
import { IconContext } from "react-icons";

import { Formik } from "formik";
import * as yup from "yup";

import { CONFIG, GLOBAL_URL_ADMIN } from "../config";
import axios from "axios";
import { ToastContext } from "../Context/ToastContext";
import { Modal } from "react-bootstrap";
import Select from "react-select";

const required = <span style={{ color: "red" }}>*</span>;

const EditQuestioniare = ({ close, data }) => {
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const toaster = useContext(ToastContext);

  const ConfirmModal = useCallback((props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="deleteModal-header">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="deleteModal-headerText"
          >
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="deleteModal-body">
          <h4 className="deleteModal-bodyText">
            Do you want to Delete Appliance{" "}
            {data.edit ? data.data.appliance_name : null} ?
          </h4>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="contained"
            id="modalDeleteBtn"
            className={"modalDeleteBtn"}
            onClick={() => deleteTypes(data.index)}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            id="modalcloseBtn"
            className={"modalcloseBtn"}
            onClick={props.onHide}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  });

  async function deleteTypes(question_id) {
    try {
      setLoading(true);
      let URL = GLOBAL_URL_ADMIN + "/questionaire/delete";
      let VAL = { question_id };

      const req = await axios.post(URL, VAL, CONFIG());

      if (req.status === 200) {
        toaster.notify("Question has been removed", "success");
        setModalShow(false);
        setLoading(false);
        close({
          state: false,
          data: null,
          edit: false,
          index: "",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error.response);
      if (error.response.status === 401) {
        toaster.notify("Unauthorised Access", "error");
      } else {
        toaster.notify("Failed to Remove", "error");
      }
    }
  }

  const initialVal = data.edit
    ? {
        question: data.data.question,

        enable: data.data.enable,

        disable: data.data.disable,
      }
    : {
        question: "",

        enable: "",

        disable: "",
      };

  return (
    <>
      <ConfirmModal show={modalShow} onHide={() => setModalShow(false)} />
      <div className="w-100 mb-5">
        <div className="row align-items-center mb-3">
          <div className="col-12">
            <div className="d-flex flex-row align-items-center  justify-content-start">
              <div
                className="mr-3"
                style={{ cursor: "pointer" }}
                onClick={() => close(false)}
              >
                <IconContext.Provider
                  value={{
                    size: "40px",
                    color: "#6a707e",
                  }}
                >
                  <MdKeyboardBackspace />
                </IconContext.Provider>
              </div>

              <h4 className="header-text " style={{ marginLeft: "20px" }}>
                {data.edit ? "Modify Questionaire" : "Create Questionaire"}
              </h4>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="loader-position">loading...</div>
        ) : (
          <>
            <div className="card-container">
              <Formik
                initialValues={initialVal}
                enableReinitialize
                validateOnChange={true}
                validateOnBlur={true}
                validationSchema={yup.object({
                  question: yup.string().required("Question is required"),

                  enable: yup.string().required("Enable is required"),
                  disable: yup.string().required("Disable is required"),
                })}
                onSubmit={async (values) => {
                  setLoading(true);
                  try {
                    let URL = GLOBAL_URL_ADMIN + "/questionaire/add";
                    let VAL = {
                      question: values.question,
                      disable: values.disable,
                      enable: values.enable,
                    };
                    if (data.edit) {
                      URL = GLOBAL_URL_ADMIN + "/questionaire/update";
                      VAL = {
                        question: values.question,
                        disable: values.disable,
                        enable: values.enable,
                        question_id: data.index,
                      };
                    }
                    //  console.log({ VAL });

                    const req = await axios.post(URL, VAL, CONFIG());

                    if (req.status === 200) {
                      toaster.notify(
                        data.edit
                          ? "Questionaire has been Updated"
                          : "Questionaire has been added",
                        "success"
                      );
                      setLoading(false);
                      close({
                        state: false,
                        data: null,
                        edit: false,
                        index: "",
                      });
                    }
                  } catch (error) {
                    console.log(error.response);
                    setLoading(false);
                    if (error.response.status === 401) {
                      toaster.notify("Unauthorised Access", "error");
                    } else {
                      toaster.notify(
                        data.edit
                          ? "Failed to update Appliance"
                          : "Failed to add Appliance",
                        "error"
                      );
                    }
                  }
                }}
              >
                {(formik) => {
                  return (
                    <>
                      <div
                        className="form-group mb-3"
                        style={{ position: "relative" }}
                        id="questionaire_c"
                      >
                        <label htmlFor="question" className="create-label">
                          Question {required}
                        </label>
                        <textarea
                          value={formik.values.question}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "question",
                              e.currentTarget.value.replace(
                                /[^a-zA-Z0-9?.%',!/() ]/g,
                                ""
                              )
                            );
                          }}
                          className="form-control addInput"
                          placeholder="question"
                          id="question"
                          style={{
                            border:
                              formik.touched.question && formik.errors.question
                                ? "1px solid red"
                                : "1px solid #B3CDFF80",
                          }}
                          rows={3}
                        ></textarea>

                        {formik.touched.question && formik.errors.question && (
                          <div className="tariff-error-position">
                            <p>{formik.errors.question}</p>
                          </div>
                        )}
                      </div>
                      <div
                        className="form-group mb-3"
                        style={{ position: "relative" }}
                        id="questionaire_c"
                      >
                        <label htmlFor="question" className="create-label">
                          On Enable {required}
                        </label>
                        <textarea
                          value={formik.values.enable}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "enable",
                              e.currentTarget.value.replace(
                                /[^a-zA-Z0-9?.%',!/() ]/g,
                                ""
                              )
                            );
                          }}
                          className="form-control addInput"
                          placeholder="enable"
                          id="enable"
                          style={{
                            border:
                              formik.touched.enable && formik.errors.enable
                                ? "1px solid red"
                                : "1px solid #B3CDFF80",
                          }}
                          rows={3}
                        ></textarea>

                        {formik.touched.enable && formik.errors.enable && (
                          <div className="tariff-error-position">
                            <p>{formik.errors.enable}</p>
                          </div>
                        )}
                      </div>
                      <div
                        className="form-group mb-3"
                        style={{ position: "relative" }}
                        id="questionaire_c"
                      >
                        <label htmlFor="disable" className="create-label">
                          On Disable {required}
                        </label>
                        <textarea
                          value={formik.values.disable}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "disable",
                              e.currentTarget.value.replace(
                                /[^a-zA-Z0-9?.%',!/() ]/g,
                                ""
                              )
                            );
                          }}
                          className="form-control addInput"
                          placeholder="disable"
                          id="disable"
                          style={{
                            border:
                              formik.touched.disable && formik.errors.disable
                                ? "1px solid red"
                                : "1px solid #B3CDFF80",
                          }}
                          rows={3}
                        ></textarea>

                        {formik.touched.disable && formik.errors.disable && (
                          <div className="tariff-error-position">
                            <p>{formik.errors.disable}</p>
                          </div>
                        )}
                      </div>

                      <div className="d-flex flex-row align-items-center justify-content-end mt-4 pt-3">
                        {data.edit ? (
                          <Button
                            variant="contained"
                            id="deleteBtn"
                            className={"deleteBtn"}
                            onClick={() => setModalShow(true)}
                            disabled={loading}
                          >
                            Delete
                          </Button>
                        ) : null}
                        <Button
                          variant="contained"
                          id="createBtn"
                          className={"createBtn"}
                          onClick={formik.handleSubmit}
                          disabled={loading}
                        >
                          {data.edit ? "Update" : "Add"}
                        </Button>
                      </div>
                    </>
                  );
                }}
              </Formik>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(EditQuestioniare);
