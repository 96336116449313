import { React, useState, useEffect } from "react";

import "../assets/css/home.css";

import { FaGem, FaHeart } from "react-icons/fa";
import {
  RiDashboardLine,
  RiLayoutGridFill,
  RiToolsFill,
  RiCalculatorFill,
} from "react-icons/ri";

import { TiPlug } from "react-icons/ti";
import { GiNotebook } from "react-icons/gi";

import { TbReportAnalytics } from "react-icons/tb";

import { BsFillPeopleFill, BsCalculatorFill } from "react-icons/bs";
import { HiOutlineDocumentReport, HiMenu } from "react-icons/hi";
import { SiGoogleanalytics } from "react-icons/si";
import { FiSettings, FiLogOut } from "react-icons/fi";
import { IconContext } from "react-icons";

import AppType from "../components/AppType";
import Appliance from "../components/Appliance";
import Questionaire from "../components/Questionaire";
import EPI from "../components/EPI";
import EnergyMeasures from "../components/EnergyMeasures";
import Tool from "./Tool";
import Leads from "../components/Lead";
import { useMediaQuery } from "react-responsive";

const Layout = (props) => {
  const [active, setActive] = useState(1);
  const [collapsed, setCollapsed] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });

  useEffect(() => {
    if (localStorage.getItem("tabIndex")) {
      setActive(parseInt(localStorage.getItem("tabIndex")));
    }
  }, []);

  // console.log({ active });
  return (
    <div className="container-fluid clear-pm">
      <div className="d-flex  flex-row">
        <div
          className={
            collapsed || isTabletOrMobile ? "sidebar-collapsed" : "sidebar"
          }
        >
          <div className="sidebar-header">
            <div className="d-flex flex-row align-items-center">
              <h1
                className="sidebar-header-text"
                style={{
                  display: collapsed || isTabletOrMobile ? "none" : "block",
                }}
              >
                CAG
              </h1>
            </div>
            <div
              onClick={() => setCollapsed(!collapsed)}
              className="sidebar-header-Icon"
            >
              <IconContext.Provider
                value={{
                  size: collapsed || isTabletOrMobile ? "50px" : "30px",
                  color: "white",
                  // className: "header__icons",
                }}
              >
                <RiToolsFill />
              </IconContext.Provider>
            </div>
          </div>

          <div className="sidebar-items">
            <div className="sidebar-section">
              <p
                className="section-header-text "
                style={{
                  display: collapsed || isTabletOrMobile ? "none" : "block",
                }}
              >
                Navigations
              </p>
              <div
                className={active === 1 ? "item-active" : "item"}
                key={1}
                onClick={() => {
                  localStorage.setItem("tabIndex", 1);
                  setActive(1);
                }}
                title="Energy Audit Tool"
              >
                <IconContext.Provider
                  value={{
                    size: collapsed || isTabletOrMobile ? "100px" : "30px",
                    className: "sidebar-icon",
                  }}
                >
                  <RiLayoutGridFill />
                </IconContext.Provider>
                <span
                  className="sidebar-item--text"
                  style={{
                    display: collapsed || isTabletOrMobile ? "none" : "block",
                  }}
                >
                  Energy Audit Tool
                </span>
              </div>
              {/* <div
                className={active === 2 ? "item-active" : "item"}
                key={2}
                onClick={() => {
                  localStorage.setItem("tabIndex", 2);
                  setActive(2);
                }}
              >
                <IconContext.Provider
                  value={{
                    size: collapsed ? "100px" : "30px",
                    className: "sidebar-icon",
                  }}
                >
                  <RiCalculatorFill />
                </IconContext.Provider>
                <span
                  className="sidebar-item--text"
                  style={{ display: collapsed ? "none" : "block" }}
                >
                  Tool
                </span>
              </div> */}
            </div>
            <div className="sidebar-section">
              <p
                className="section-header-text "
                style={{
                  display: collapsed || isTabletOrMobile ? "none" : "block",
                }}
              >
                Forms
              </p>
              <div
                className={active === 2 ? "item-active" : "item"}
                key={2}
                onClick={() => {
                  localStorage.setItem("tabIndex", 2);
                  setActive(2);
                }}
                title=" Appliances Type"
              >
                <SiGoogleanalytics className="sidebar-icon" />
                <span
                  className="sidebar-item--text"
                  style={{
                    display: collapsed || isTabletOrMobile ? "none" : "block",
                  }}
                >
                  Appliances Type
                </span>
              </div>
              <div
                className={active === 3 ? "item-active" : "item"}
                key={3}
                onClick={() => {
                  localStorage.setItem("tabIndex", 3);
                  setActive(3);
                }}
                title="Appliances"
              >
                <TiPlug className="sidebar-icon" />
                <span
                  className="sidebar-item--text"
                  style={{
                    display: collapsed || isTabletOrMobile ? "none" : "block",
                  }}
                >
                  Appliances
                </span>
              </div>
              <div
                className={active === 4 ? "item-active" : "item"}
                key={4}
                onClick={() => {
                  localStorage.setItem("tabIndex", 4);
                  setActive(4);
                }}
                title="Questionaire"
              >
                <TbReportAnalytics className="sidebar-icon" />

                <span
                  className="sidebar-item--text"
                  style={{
                    display: collapsed || isTabletOrMobile ? "none" : "block",
                  }}
                >
                  Questionaire
                </span>
              </div>

              <div
                className={active === 5 ? "item-active" : "item"}
                key={5}
                onClick={() => {
                  localStorage.setItem("tabIndex", 5);
                  setActive(5);
                }}
                title="Energy Saving Measures"
              >
                <GiNotebook className="sidebar-icon" />
                <span
                  className="sidebar-item--text"
                  style={{
                    display: collapsed || isTabletOrMobile ? "none" : "block",
                  }}
                >
                  Energy Saving Measures
                </span>
              </div>
              <div
                className={active === 6 ? "item-active" : "item"}
                key={6}
                onClick={() => {
                  localStorage.setItem("tabIndex", 6);
                  setActive(6);
                }}
                title="Energy Performance Index"
              >
                <BsCalculatorFill className="sidebar-icon" />
                <span
                  className="sidebar-item--text"
                  style={{
                    display: collapsed || isTabletOrMobile ? "none" : "block",
                  }}
                >
                  EPI
                </span>
              </div>
              <div
                className={active === 7 ? "item-active" : "item"}
                key={7}
                onClick={() => {
                  localStorage.setItem("tabIndex", 7);
                  setActive(7);
                }}
                title="Leads"
              >
                <BsFillPeopleFill className="sidebar-icon" />
                <span
                  className="sidebar-item--text"
                  style={{
                    display: collapsed || isTabletOrMobile ? "none" : "block",
                  }}
                >
                  Leads
                </span>
              </div>
            </div>
            <div className="sidebar-section" style={{ borderBottom: "0px" }}>
              <p
                className="section-header-text "
                style={{
                  display: collapsed || isTabletOrMobile ? "none" : "block",
                }}
              >
                Settings
              </p>
              <div
                className={"item"}
                key={4}
                onClick={() => {
                  localStorage.removeItem("tabIndex");
                  localStorage.removeItem("cag_key");
                  window.location.href = "/";
                  return;
                }}
                title="Logout"
              >
                <FiLogOut className="sidebar-icon" />
                <span
                  className="sidebar-item--text"
                  style={{
                    display: collapsed || isTabletOrMobile ? "none" : "block",
                  }}
                >
                  Logout
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column  w-100">
          {/* <div className="nav-header">
            <div className="d-flex flex-row align-items-center">
              <div className="image-container">
                <img
                  src="https://toppng.com/uploads/preview/file-svg-profile-icon-vector-11562942678pprjdh47a8.png"
                  style={{ width: "50px", height: "50px" }}
                />
              </div>
              <IconContext.Provider
                value={{
                  size: "30px",
                  color: "black",
                  // className: "header__icons",
                }}
              >
                <HiMenu />
              </IconContext.Provider>
            </div>
          </div> */}
          <div
            className="content-area"
            style={{
              marginLeft: collapsed || isTabletOrMobile ? "100px" : "320px",
            }}
          >
            {active === 1 && (
              <>
                <iframe
                  src="https://energyaudit.cag.org.in/tool"
                  width="100%"
                  height="100%"
                />
              </>
            )}
            {active === 2 && <AppType />}
            {active === 3 && <Appliance />}
            {active === 4 && <Questionaire />}
            {active === 5 && <EnergyMeasures />}
            {active === 6 && <EPI />}
            {active === 7 && <Leads />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
