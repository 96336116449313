import React, { useState, useEffect, useContext } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "../assets/css/pdfView.css";
import { MdKeyboardBackspace } from "react-icons/md";
import {
  FaFacebook,
  FaLinkedin,
  FaTwitterSquare,
  FaFacebookSquare,
} from "react-icons/fa";
import { IconContext } from "react-icons";
import { ToastContext } from "../Context/ToastContext";
export default function PdfViewer({ pdfData, fname, previous }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfString, setPdfString] = useState("");
  const toaster = useContext(ToastContext);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  // pdfjs.GlobalWorkerOptions.workerSrc = `https://energyaudit.cag.org.in/static/css/pdfwrker.js`;
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  useEffect(() => {
    let base64String;

    setPdfString(true);
    //   let reader = new FileReader();
    //   reader.readAsDataURL([pdfData.blob]);
    //   reader.onloadend = () => {
    //     base64String = reader.result;
    //     setPdfString(base64String.substr(base64String.indexOf(",") + 1));
    //   };
  }, []);
  const downloadPDF = () => {
    // console.log("blob file", blobFile);

    const link = document.createElement("a");
    link.href = pdfData.url;
    link.setAttribute("download", `${fname}\'s-Energy-Audit-Report.pdf`);
    document.body.appendChild(link);
    link.click();
  };

  //console.log({ pdfData, pdfString });
  return (
    <div className="loginContainer">
      {pdfString ? (
        <>
          <div className="form-header">
            <div className="d-flex  flex-sm-row align-items-center justify-content-between flex-column">
              <div className="d-flex flex-row align-items-center justify-content-start">
                <IconContext.Provider
                  value={{
                    size: "30px",
                    color: "#6a707e",
                    cursor: "pointer",
                  }}
                >
                  <MdKeyboardBackspace
                    onClick={() => previous(3)}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </IconContext.Provider>
                <h1
                  className="primaryColor caption ms-1"
                  onClick={() => previous(3)}
                >
                  Energy Audit Report
                </h1>
              </div>

              <div className="d-flex align-items-center ">
                <div className="d-flex align-items-center">
                  {/* <div>Share: </div> */}
                  <span
                    style={{ cursor: "pointer", marginRight: 4 }}
                    title="Facebook"
                  >
                    <FaFacebookSquare
                      onClick={() =>
                        window.open(
                          "https://www.facebook.com/sharer/sharer.php?u=https://energyaudit.cag.org.in/tool",
                          "_blank"
                        )
                      }
                      color="#3B5998"
                      size={40}
                    />
                  </span>
                  <span
                    style={{ cursor: "pointer", marginRight: 4 }}
                    title="Linkedin"
                  >
                    <FaLinkedin
                      onClick={() =>
                        window.open(
                          "https://www.linkedin.com/shareArticle?mini=true&url=https://energyaudit.cag.org.in/tool",
                          "_blank"
                        )
                      }
                      color="#0A66C2"
                      size={40}
                    />
                  </span>
                  <span
                    style={{ cursor: "pointer", marginRight: 4 }}
                    title="Twitter"
                  >
                    <FaTwitterSquare
                      onClick={() =>
                        window.open(
                          "https://twitter.com/intent/tweet?url=https://energyaudit.cag.org.in/tool&text=Use CAG's Energy Audit Tool to cut down on those electricity bills!",
                          "_blank"
                        )
                      }
                      color="#1C93E4"
                      size={40}
                    />
                  </span>
                </div>
                <button
                  className="btn btn-warning text-white ms-2"
                  type="button"
                  onClick={downloadPDF}
                >
                  Download
                </button>
              </div>
            </div>
          </div>
          <div
            className="bg-success text-white text-center "
            style={{
              width: "98%",
              borderRadius: 5,
            }}
          >
            Report has been sent to your email
          </div>
          <div className="loginForm pdfContainer">
            <Document file={pdfData.url} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from({ length: numPages }, (_, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  options={{
                    workerSrc: "/pdfwrker.js",
                  }}
                  scale={3}
                />
              ))}
            </Document>
          </div>
        </>
      ) : (
        "no Data"
      )}
    </div>
  );
}
