import React, { useEffect, useState, useContext, useCallback } from "react";

import "../assets/css/apps.css";

import { Button } from "@mui/material";

import { MdKeyboardBackspace } from "react-icons/md";
import { IconContext } from "react-icons";

import { Formik } from "formik";
import * as yup from "yup";

import { CONFIG, GLOBAL_URL_ADMIN } from "../config";
import axios from "axios";
import { ToastContext } from "../Context/ToastContext";
import { Modal } from "react-bootstrap";
import Select from "react-select";

const required = <span style={{ color: "red" }}>*</span>;

const EditLeads = ({ close, data }) => {
  const [loading, setLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [userData, setData] = useState(null);
  const [activeTab, setTab] = useState(1);

  const toaster = useContext(ToastContext);

  const ConfirmModal = useCallback((props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="deleteModal-header">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="deleteModal-headerText"
          >
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="deleteModal-body">
          <h4 className="deleteModal-bodyText">
            Do you want to delete this Energy Measure ?
          </h4>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="contained"
            id="modalDeleteBtn"
            className={"modalDeleteBtn"}
            onClick={() => deleteTypes(data.index)}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            id="modalcloseBtn"
            className={"modalcloseBtn"}
            onClick={props.onHide}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  });

  async function deleteTypes(measure_id) {
    try {
      setLoading(true);
      let URL = GLOBAL_URL_ADMIN + "/measures/delete";
      let VAL = { measure_id };

      const req = await axios.post(URL, VAL, CONFIG());

      if (req.status === 200) {
        toaster.notify("Question has been removed", "success");
        setModalShow(false);
        setLoading(false);
        close({
          state: false,
          data: null,
          edit: false,
          index: "",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error.response);
      if (error.response.status === 401) {
        toaster.notify("Unauthorised Access", "error");
      } else {
        toaster.notify("Failed to Remove", "error");
      }
    }
  }
  const categoryOptions = [
    {
      label: "Energy Measures",
      value: 0,
    },
    {
      label: "Passive Techniques",
      value: 1,
    },
  ];

  async function fetchData() {
    try {
      setLoading(true);
      let URL = GLOBAL_URL_ADMIN + `/leads/${data.index}`;
      //   let VAL = { measure_id };

      const req = await axios.get(URL, CONFIG());
      // console.log(req);

      if (req.status === 200) {
        setData(req.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error.response);
      if (error.response.status === 401) {
        toaster.notify("Unauthorised Access", "error");
      } else {
        toaster.notify("Failed to get data", "error");
      }
    }
  }
  async function generateReport() {
    let data = {
      personal: userData.personal,

      consumptionItems: userData.items,
      questionaire: userData.questionaire,
    };

    try {
      setGenerating(true);
      const result = await axios.post(
        GLOBAL_URL_ADMIN + "/leads/report/generate",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("cag_token")}`,
          },
          responseType: "blob",
        }
      );
      // console.log({ result });
      if (result.status === 200) {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const file = new Blob([result.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${userData.personal.firstname}\`s-Energy-Audit-Report.pdf`
        );
        document.body.appendChild(link);
        link.click();
        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
        link.parentNode.removeChild(link);
        return setGenerating(false);
        // setBlob(data);
      }
    } catch (error) {
      if (error instanceof TypeError) {
        setGenerating(false);
        // statements to handle TypeError exceptions
        return toaster.notify("Report Downloaded", "success");
      }

      toaster.notify("Report Generation Failed", "error");
      setGenerating(false);
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  //   const initialVal = data.edit
  //     ? {
  //         measures: data.data.measures,
  //         category: data.data.category,
  //       }
  //     : {
  //         measures: "",
  //         category: 0,
  //       };

  const Personal = () => {
    return (
      <>
        <section class="personal">
          {userData ? (
            <div class="row">
              <div class="col-12 table-responsive ">
                <table class="table  table-striped table-bordered border-light">
                  <tbody>
                    <tr>
                      <th scope="row">First Name</th>
                      <td>{userData.personal.firstname}</td>
                    </tr>
                    <tr>
                      <th scope="row">Last Name</th>
                      <td>{userData.personal.lastname}</td>
                    </tr>
                    <tr>
                      <th scope="row">Email</th>
                      <td>{userData.personal.email}</td>
                    </tr>
                    <tr>
                      <th scope="row">Address</th>

                      <td>
                        {userData.personal.address1},
                        {userData.personal.address2},{userData.personal.city},
                        {userData.personal.state},{userData.personal.pincode}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Build Up Area(sq ft)</th>

                      <td>{userData.personal.area}</td>
                    </tr>
                    <tr>
                      <th scope="row">No. Of Rooms</th>

                      <td>{userData.personal.rooms}</td>
                    </tr>
                    <tr>
                      <th scope="row">High Consumption Month</th>

                      <td>{userData.personal.highmonth}</td>
                    </tr>
                    <tr>
                      <th scope="row">Annual Energy Consumed(kWh)</th>

                      <td>{userData.personal.annual_consumption}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-12">
                <div class="row align-items-center epi-box ">
                  <div class="col-9">
                    <p>Energy Performance Index (kWh/Sq m/year)</p>
                  </div>
                  <div class="col-3">
                    <p class="lastrowval">{userData.personal.EPI}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </section>
      </>
    );
  };
  const Consumption = () => {
    return (
      <>
        <section class="personal">
          {userData ? (
            <div class="row consumptionlist">
              <div class="col-12 table-responsive">
                <table class="table  table-striped table-bordered border-light">
                  <thead>
                    <th>#</th>
                    <th>Appliance</th>
                    <th>Type</th>
                    <th>Quantity</th>
                    <th>Watts</th>
                    <th>Peak Hours</th>
                    <th>Non-Peak Hours</th>
                    <th>Annual Consumption</th>
                  </thead>
                  <tbody>
                    {userData.items.length
                      ? userData.items.map((appd, i) => {
                          return (
                            <>
                              <tr>
                                <td>{i + 1}</td>

                                <td>{appd.app_name}</td>
                                <td>{appd.app_type}</td>
                                <td>{appd.quantity}</td>
                                <td>{appd.watts}</td>
                                <td>{appd.day_peak_hours}</td>
                                <td>{appd.day_nonpeak_hours}</td>
                                <td>{appd.annual_consumption}</td>
                              </tr>
                            </>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}
        </section>
      </>
    );
  };

  const Questionaire = () => {
    return (
      <>
        <section class="personal">
          {userData ? (
            <div class="row consumptionlist">
              <div class="col-12 table-responsive ">
                <table class="table  table-striped table-bordered border-light">
                  <thead>
                    <th>#</th>
                    <th>Question</th>
                    <th>Answer</th>
                  </thead>
                  <tbody>
                    {userData.questionaire.length
                      ? userData.questionaire.map((appd, i) => {
                          // console.log({ appd });
                          return (
                            <>
                              <tr>
                                <td>{i + 1}</td>

                                <td>{appd.question}</td>
                                <td>{parseInt(appd.answer) ? "Yes" : "No"}</td>
                              </tr>
                            </>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}
        </section>
      </>
    );
  };
  return (
    <>
      <ConfirmModal show={modalShow} onHide={() => setModalShow(false)} />
      <div className="w-100 mb-5">
        <div className="row align-items-center mb-3">
          <div className="col-12">
            <div className="d-flex flex-row align-items-center  justify-content-between">
              <div
                className="mr-3"
                style={{ cursor: "pointer" }}
                onClick={() => close(false)}
              >
                <IconContext.Provider
                  value={{
                    size: "40px",
                    color: "#6a707e",
                  }}
                >
                  <MdKeyboardBackspace />
                </IconContext.Provider>
              </div>
              <h4 className="header-text " style={{ marginLeft: "20px" }}>
                Lead View
              </h4>
              <button
                className="btn btn-warning"
                onClick={() => {
                  generateReport(userData);
                }}
                disabled={generating}
              >
                {generating ? "generating ..." : "View Report"}
              </button>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="loader-position">loading...</div>
        ) : (
          <>
            <div className="row align-items-center justify-content-between lead-tab-container">
              <div
                onClick={() => setTab(1)}
                className={
                  activeTab === 1
                    ? "col-4 tab-item border-right tab-item-active"
                    : "col-4 tab-item border-right"
                }
              >
                Personal
              </div>
              <div
                onClick={() => setTab(2)}
                className={
                  activeTab === 2
                    ? "col-4 tab-item border-right tab-item-active"
                    : "col-4 tab-item border-right"
                }
              >
                Consumption
              </div>
              <div
                onClick={() => setTab(3)}
                className={
                  activeTab === 3
                    ? "col-4 tab-item border-right tab-item-active"
                    : "col-4 tab-item border-right"
                }
              >
                Questionaire
              </div>
            </div>
            <div className="lead-card-container">
              {activeTab === 1 && <Personal />}
              {activeTab === 2 && <Consumption />}
              {activeTab === 3 && <Questionaire />}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(EditLeads);
