import React, { useEffect, useState, useContext, useCallback } from "react";

import "../assets/css/apps.css";

import { Button } from "@mui/material";

import { MdKeyboardBackspace } from "react-icons/md";
import { IconContext } from "react-icons";

import { Formik } from "formik";
import * as yup from "yup";

import { CONFIG, GLOBAL_URL_ADMIN } from "../config";
import axios from "axios";
import { ToastContext } from "../Context/ToastContext";
import { Modal } from "react-bootstrap";

const required = <span style={{ color: "red" }}>*</span>;

const EditType = ({ close, data }) => {
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const toaster = useContext(ToastContext);

  const ConfirmModal = useCallback((props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="deleteModal-header">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="deleteModal-headerText"
          >
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="deleteModal-body">
          <h4 className="deleteModal-bodyText">
            Do you want to Delete Appliance type{" "}
            {data.edit ? data.data.app_type : null} ?
          </h4>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="contained"
            id="modalDeleteBtn"
            className={"modalDeleteBtn"}
            onClick={() => deleteTypes(data.index)}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            id="modalcloseBtn"
            className={"modalcloseBtn"}
            onClick={props.onHide}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  });

  async function deleteTypes(app_type_id) {
    try {
      let URL = GLOBAL_URL_ADMIN + "/appliances/type/delete";
      let VAL = { app_type_id };

      const req = await axios.post(URL, VAL, CONFIG());

      if (req.status === 200) {
        toaster.notify("Type has been removed", "success");
        setModalShow(false);
        close({
          state: false,
          data: null,
          edit: false,
          index: "",
        });
      }
    } catch (error) {
      console.log(error.response);
      if (error.response.status === 401) {
        toaster.notify("Unauthorised Access", "error");
      } else {
        toaster.notify("Failed to Remove", "error");
      }
    }
  }
  return (
    <>
      <ConfirmModal show={modalShow} onHide={() => setModalShow(false)} />
      <div className="w-100 mb-5">
        <div className="row align-items-center mb-3">
          <div className="col-12">
            <div className="d-flex flex-row align-items-center  justify-content-start">
              <div
                className="mr-3"
                style={{ cursor: "pointer" }}
                onClick={() => close(false)}
              >
                <IconContext.Provider
                  value={{
                    size: "40px",
                    color: "#6a707e",
                  }}
                >
                  <MdKeyboardBackspace />
                </IconContext.Provider>
              </div>

              <h4 className="header-text " style={{ marginLeft: "20px" }}>
                {data.edit ? "Modify Appliance Type" : "Create Appliances Type"}
              </h4>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="loader-position">loading...</div>
        ) : (
          <>
            <div className="card-container">
              <Formik
                initialValues={{
                  app_type: data.edit ? data.data.app_type : "",
                }}
                enableReinitialize
                validateOnChange={true}
                validateOnBlur={true}
                validationSchema={yup.object({
                  app_type: yup
                    .string()
                    .required("Appliances type is required"),
                })}
                onSubmit={async (values) => {
                  //   console.log({ values });
                  try {
                    let URL = GLOBAL_URL_ADMIN + "/appliances/type/add";
                    let VAL = { ...values };
                    if (data.edit) {
                      URL = GLOBAL_URL_ADMIN + "/appliances/type/update";
                      VAL = { ...values, app_type_id: data.index };
                    }

                    const req = await axios.post(URL, VAL, CONFIG());

                    if (req.status === 200) {
                      toaster.notify(
                        data.edit
                          ? "Type has been Updated"
                          : "Type has been added",
                        "success"
                      );
                      close({
                        state: false,
                        data: null,
                        edit: false,
                        index: "",
                      });
                    }
                  } catch (error) {
                    console.log(error.response);
                    if (error.response.status === 401) {
                      toaster.notify("Unauthorised Access", "error");
                    } else {
                      toaster.notify(
                        data.edit
                          ? "Failed to update type"
                          : "Failed to add type",
                        "error"
                      );
                    }
                  }
                }}
              >
                {(formik) => {
                  return (
                    <>
                      <div
                        className="form-group mb-3"
                        style={{ position: "relative" }}
                        id="app_type_c"
                      >
                        <label htmlFor="status" className="create-label">
                          Appliance Type {required}
                        </label>
                        <input
                          value={formik.values.app_type}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "app_type",
                              e.currentTarget.value.replace(/[^a-zA-Z/  ]/g, "")
                            );
                          }}
                          className="form-control addInput"
                          placeholder="Appliance Type"
                          id="app_type"
                          styles={{
                            border:
                              formik.touched.app_type && formik.errors.app_type
                                ? "1px solid red"
                                : "1px solid #B3CDFF80",
                          }}
                        />

                        {formik.touched.app_type && formik.errors.app_type && (
                          <div className="tariff-error-position">
                            <p>{formik.errors.app_type}</p>
                          </div>
                        )}
                      </div>

                      <div className="d-flex flex-row align-items-center justify-content-end mt-4 pt-3">
                        {data.edit ? (
                          <Button
                            variant="contained"
                            id="deleteBtn"
                            className={"deleteBtn"}
                            onClick={() => setModalShow(true)}
                            // disabled={true}
                          >
                            Delete
                          </Button>
                        ) : null}
                        <Button
                          variant="contained"
                          id="createBtn"
                          className={"createBtn"}
                          onClick={formik.handleSubmit}
                        >
                          {data.edit ? "Update" : "Add"}
                        </Button>
                      </div>
                    </>
                  );
                }}
              </Formik>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(EditType);
