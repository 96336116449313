import React, { useEffect, useState, useContext, useCallback } from "react";

import "../assets/css/apps.css";

import { Button } from "@mui/material";

import { MdKeyboardBackspace } from "react-icons/md";
import { IconContext } from "react-icons";

import { Formik, FieldArray } from "formik";
import * as yup from "yup";

import { CONFIG, GLOBAL_URL_ADMIN } from "../config";
import axios from "axios";
import { ToastContext } from "../Context/ToastContext";
import { Modal } from "react-bootstrap";
import Select from "react-select";

const required = <span style={{ color: "red" }}>*</span>;

const EditEPI = ({ close, data }) => {
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const toaster = useContext(ToastContext);

  const ConfirmModal = useCallback((props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="deleteModal-header">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="deleteModal-headerText"
          >
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="deleteModal-body">
          <h4 className="deleteModal-bodyText">
            Do you want to Delete Appliance{" "}
            {data.edit ? data.data.appliance_name : null} ?
          </h4>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="contained"
            id="modalDeleteBtn"
            className={"modalDeleteBtn"}
            onClick={() => deleteTypes(data.index)}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            id="modalcloseBtn"
            className={"modalcloseBtn"}
            onClick={props.onHide}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  });

  async function deleteTypes(question_id) {
    try {
      setLoading(true);
      let URL = GLOBAL_URL_ADMIN + "/questionaire/delete";
      let VAL = { question_id };

      const req = await axios.post(URL, VAL, CONFIG());

      if (req.status === 200) {
        toaster.notify("Question has been removed", "success");
        setModalShow(false);
        setLoading(false);
        close({
          state: false,
          data: null,
          edit: false,
          index: "",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error.response);
      if (error.response.status === 401) {
        toaster.notify("Unauthorised Access", "error");
      } else {
        toaster.notify("Failed to Remove", "error");
      }
    }
  }

  const initialVal = data.edit
    ? {
        epi_range: data.data.epi_range,

        epi_val_desc: data.data.epi_val_desc,

        title: data.data.further_saving_title,
        further_desc: data.data.further_desc.split(","),
      }
    : {
        epi_range: data.data.epi_range,

        epi_val_desc: data.data.epi_val_desc,

        title: data.data.further_saving_title,
        further_desc: data.data.further_desc.split(","),
      };

  return (
    <>
      <ConfirmModal show={modalShow} onHide={() => setModalShow(false)} />
      <div className="w-100 mb-5">
        <div className="row align-items-center mb-3">
          <div className="col-12">
            <div className="d-flex flex-row align-items-center  justify-content-start">
              <div
                className="mr-3"
                style={{ cursor: "pointer" }}
                onClick={() => close(false)}
              >
                <IconContext.Provider
                  value={{
                    size: "40px",
                    color: "#6a707e",
                  }}
                >
                  <MdKeyboardBackspace />
                </IconContext.Provider>
              </div>

              <h4 className="header-text " style={{ marginLeft: "20px" }}>
                {data.edit ? "Modify EPI" : "Create EPI"}
              </h4>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="loader-position">loading...</div>
        ) : (
          <>
            <div className="card-container">
              <Formik
                initialValues={initialVal}
                enableReinitialize
                validateOnChange={true}
                validateOnBlur={true}
                validationSchema={yup.object({
                  epi_range: yup.string().required("Range is required"),

                  epi_val_desc: yup.string().required("Result is required"),

                  title: yup.string().required("Title is required"),
                  further_desc: yup
                    .array()
                    .of(yup.string().required("Field cannot be empty"))
                    .min(1, "Add atleast one description ")
                    .required("Description is required"),
                })}
                onSubmit={async (values) => {
                  // console.log({ values, data: data });
                  setLoading(true);
                  try {
                    let URL = GLOBAL_URL_ADMIN + "/epi/update";
                    let VAL = {
                      id: data.index,

                      epi_val_desc: values.epi_val_desc,

                      title: values.title,
                      further_desc: values.further_desc.toString(),
                    };

                    const req = await axios.post(URL, VAL, CONFIG());

                    if (req.status === 200) {
                      toaster.notify(
                        data.edit
                          ? "EPI has been Updated"
                          : "EPI has been added",
                        "success"
                      );
                      setLoading(false);
                      close({
                        state: false,
                        data: null,
                        edit: false,
                        index: "",
                      });
                    }
                  } catch (error) {
                    console.log(error.response);
                    setLoading(false);
                    if (error.response.status === 401) {
                      toaster.notify("Unauthorised Access", "error");
                    } else {
                      toaster.notify(
                        data.edit
                          ? "Failed to update EPI"
                          : "Failed to add EPI",
                        "error"
                      );
                    }
                  }
                }}
              >
                {(formik) => {
                  // console.log(formik.errors);
                  return (
                    <>
                      <div
                        className="form-group mb-3"
                        style={{ position: "relative" }}
                        id="epi_c"
                      >
                        <label htmlFor="epi_range" className="create-label">
                          EPI RANGE {required}
                        </label>
                        <input
                          value={formik.values.epi_range}
                          className="form-control addInput"
                          placeholder="EPI Range"
                          id="epi_range"
                          style={{
                            border: "1px solid #B3CDFF80",
                          }}
                          disabled={true}
                        />
                      </div>
                      <div
                        className="form-group mb-3"
                        style={{ position: "relative" }}
                        id="epi_c"
                      >
                        <label htmlFor="epi_val_desc" className="create-label">
                          Result {required}
                        </label>
                        <textarea
                          value={formik.values.epi_val_desc}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "epi_val_desc",
                              e.currentTarget.value.replace(
                                /[^a-zA-Z0-9?._!-\/\ ]/g,
                                ""
                              )
                            );
                          }}
                          className="form-control addInput"
                          placeholder="result"
                          id="epi_val_desc"
                          style={{
                            border:
                              formik.touched.epi_val_desc &&
                              formik.errors.epi_val_desc
                                ? "1px solid red"
                                : "1px solid #B3CDFF80",
                          }}
                          rows={3}
                        ></textarea>

                        {formik.touched.epi_val_desc &&
                          formik.errors.epi_val_desc && (
                            <div className="tariff-error-position">
                              <p>{formik.errors.epi_val_desc}</p>
                            </div>
                          )}
                      </div>
                      <div
                        className="form-group mb-3"
                        style={{ position: "relative" }}
                        id="epi_c"
                      >
                        <label htmlFor="title" className="create-label">
                          Title {required}
                        </label>
                        <textarea
                          value={formik.values.title}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "title",
                              e.currentTarget.value.replace(
                                /[^a-zA-Z0-9?._!-\/\ ]/g,
                                ""
                              )
                            );
                          }}
                          className="form-control addInput"
                          placeholder="title"
                          id="title"
                          style={{
                            border:
                              formik.touched.title && formik.errors.title
                                ? "1px solid red"
                                : "1px solid #B3CDFF80",
                          }}
                          rows={2}
                        ></textarea>

                        {formik.touched.title && formik.errors.title && (
                          <div className="tariff-error-position">
                            <p>{formik.errors.title}</p>
                          </div>
                        )}
                      </div>
                      <div
                        className="form-group mb-3"
                        style={{ position: "relative" }}
                        id="epi_c"
                      >
                        <div>
                          <FieldArray name={"further_desc"}>
                            {({ form, push, remove }) => {
                              const { values } = form;
                              const { further_desc } = values;
                              const onAddClick = () => {
                                push("");
                              };

                              return (
                                <>
                                  <div className="d-flex flex-row justify-content-between align-items-center ">
                                    <label
                                      htmlFor="title"
                                      className="create-label"
                                    >
                                      DESCRIPTION {required}
                                    </label>
                                    <p
                                      onClick={onAddClick}
                                      className="desc-link"
                                    >
                                      add
                                    </p>
                                  </div>
                                  {further_desc.map((td, i) => {
                                    return (
                                      <>
                                        <div className="desc-box">
                                          <div className="desc-input">
                                            <input
                                              value={td}
                                              onChange={(e) => {
                                                formik.setFieldValue(
                                                  `further_desc[${i}]`,
                                                  e.currentTarget.value.replace(
                                                    /[^a-zA-Z0-9!().-_ ]/g,
                                                    ""
                                                  )
                                                );
                                              }}
                                              style={{
                                                border:
                                                  formik.errors.further_desc &&
                                                  formik.errors.further_desc[i]
                                                    ? "1px solid red"
                                                    : "1px solid #B3CDFF80",
                                              }}
                                              className="form-control addInput"
                                              placeholder="Appliance name"
                                              id="appliance_name"
                                            />
                                            {formik.errors.further_desc &&
                                              formik.errors.further_desc[i] && (
                                                <div className="tariff-error-position">
                                                  <p>
                                                    {
                                                      formik.errors
                                                        .further_desc[i]
                                                    }
                                                  </p>
                                                </div>
                                              )}
                                          </div>
                                          {i > 0 && (
                                            <div>
                                              <p
                                                onClick={() => remove(i)}
                                                className="desc-remove"
                                              >
                                                remove
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                      </>
                                    );
                                  })}
                                </>
                              );
                            }}
                          </FieldArray>
                        </div>
                      </div>

                      <div className="d-flex flex-row align-items-center justify-content-end mt-4 pt-3">
                        {/* {data.edit ? (
                          <Button
                            variant="contained"
                            id="deleteBtn"
                            className={"deleteBtn"}
                            onClick={() => setModalShow(true)}
                            disabled={loading}
                          >
                            Delete
                          </Button>
                        ) : null} */}
                        <Button
                          variant="contained"
                          id="createBtn"
                          className={"createBtn"}
                          onClick={formik.handleSubmit}
                          disabled={loading}
                        >
                          {data.edit ? "Update" : "Add"}
                        </Button>
                      </div>
                    </>
                  );
                }}
              </Formik>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(EditEPI);
