import React from "react";
import "../assets/css/login.css";
import logo from "../assets/img/logo.png";
import { TextField } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { Fade } from "react-reveal";

import { useNavigate } from "react-router-dom";

import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { GLOBAL_URL_ADMIN, CONFIG } from "../config";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#e27e2f",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#e27e2f",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "red",
    },
    "&:hover fieldset": {
      borderColor: "yellow",
    },
    "&.Mui-focused fieldset": {
      borderColor: "green",
    },
  },
});

export default function Login() {
  const navigate = useNavigate();
  return (
    <>
      <section className="main-wrapper">
        <div className="d-flex  flex-container ">
          <div className="sidebar-container bgColor ">
            <div className="bg-white">
              <img src={logo} alt="cag-logo" className="img-fluid" />
            </div>
          </div>

          <Formik
            initialValues={{
              user: "",
              password: "",
            }}
            validateOnChange
            validateOnBlur
            validationSchema={yup.object({
              user: yup.string().email().required("Username Required"),
              password: yup
                .string()
                .min(8)
                .max(10)
                .required("Password Required"),
            })}
            onSubmit={async (values, formik) => {
              // console.log(values);
              try {
                const req1 = await axios.post(
                  GLOBAL_URL_ADMIN + "/login",
                  {
                    email: values.user,
                    password: values.password,
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                );
                if (req1.status === 200) {
                  let token = req1.data.token;
                  localStorage.setItem("cag_token", token);
                  navigate("/home");
                }
              } catch (error) {
                console.log(error);
                if (error.response.status === 401) {
                  formik.setFieldError("user", "Invalid Email and Password");
                }
              }
            }}
          >
            {(formik) => {
              return (
                <div className="loginContainer">
                  <h1 className="primaryColor caption">
                    Energy Audit Calculator
                  </h1>
                  <Fade bottom>
                    <div className="loginForm">
                      <form onSubmit={formik.handleSubmit}>
                        <div
                          className="form-inputs
                        "
                        >
                          <CssTextField
                            label="Username"
                            variant="standard"
                            fullWidth
                            value={formik.values.user}
                            error={formik.touched.user && formik.errors.user}
                            onChange={formik.handleChange("user")}
                            onBlur={formik.handleBlur("user")}
                          />
                          <Fade bottom collapse opposite>
                            {formik.touched.user && formik.errors.user && (
                              <p className="error">{formik.errors.user}</p>
                            )}
                          </Fade>
                        </div>
                        <div
                          className="form-inputs
                        "
                        >
                          <CssTextField
                            label="Password"
                            type="password"
                            variant="standard"
                            fullWidth
                            value={formik.values.password}
                            error={
                              formik.touched.password && formik.errors.password
                            }
                            onChange={formik.handleChange("password")}
                            onBlur={formik.handleBlur("password")}
                          />
                          <Fade bottom collapse opposite>
                            {formik.touched.password &&
                              formik.errors.password && (
                                <p className="error">
                                  {formik.errors.password}
                                </p>
                              )}
                          </Fade>
                        </div>
                        <div className="form-btn">
                          <button
                            className="btn"
                            type="submit"
                            onClick={formik.handleSubmit}
                          >
                            login
                          </button>
                        </div>
                      </form>
                    </div>
                  </Fade>
                </div>
              );
            }}
          </Formik>
        </div>
      </section>
    </>
  );
}
