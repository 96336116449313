import React, { useEffect, useState, useContext, useCallback } from "react";

import "../assets/css/apps.css";

import { Button } from "@mui/material";

import { MdKeyboardBackspace } from "react-icons/md";
import { IconContext } from "react-icons";

import { Formik, FieldArray } from "formik";
import * as yup from "yup";

import { CONFIG, GLOBAL_URL_ADMIN } from "../config";
import axios from "axios";
import { ToastContext } from "../Context/ToastContext";
import { Modal } from "react-bootstrap";
import Select from "react-select";

const required = <span style={{ color: "red" }}>*</span>;

const EditMeasures = ({ close, data }) => {
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [appsTypesList, setTypeList] = useState([]);

  const toaster = useContext(ToastContext);

  const ConfirmModal = useCallback((props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="deleteModal-header">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="deleteModal-headerText"
          >
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="deleteModal-body">
          <h4 className="deleteModal-bodyText">
            Do you want to delete this Energy Measure ?
          </h4>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="contained"
            id="modalDeleteBtn"
            className={"modalDeleteBtn"}
            onClick={() => deleteTypes(data.index)}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            id="modalcloseBtn"
            className={"modalcloseBtn"}
            onClick={props.onHide}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  });

  async function deleteTypes(measure_id) {
    try {
      setLoading(true);
      let URL = GLOBAL_URL_ADMIN + "/measures/delete";
      let VAL = { measure_id };

      const req = await axios.post(URL, VAL, CONFIG());

      if (req.status === 200) {
        toaster.notify("Question has been removed", "success");
        setModalShow(false);
        setLoading(false);
        close({
          state: false,
          data: null,
          edit: false,
          index: "",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error.response);
      if (error.response.status === 401) {
        toaster.notify("Unauthorised Access", "error");
      } else {
        toaster.notify("Failed to Remove", "error");
      }
    }
  }

  const typeList = useCallback(async () => {
    try {
      const res = await axios.get(
        GLOBAL_URL_ADMIN + "/appliances/types",
        CONFIG()
      );
      //  console.log(res);
      if (res.status === 200) {
        let options = [];
        res.data.data.map((td) => {
          return options.push({ label: td.app_type, value: td.id });
        });
        setTypeList(options);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    typeList();
  }, []);
  const categoryOptions = [
    {
      label: "Energy Measures",
      value: 0,
    },
    {
      label: "Passive Techniques",
      value: 1,
    },
  ];

  const initialVal = data.edit
    ? {
        measures: data.data.measures.split(","),
        category: data.data.category,
        appliances: data.data.app_name,
      }
    : {
        measures: [""],
        category: "",
        appliances: "",
      };

  return (
    <>
      <div className="w-100 mb-5">
        <ConfirmModal show={modalShow} onHide={() => setModalShow(false)} />
        <div className="row align-items-center mb-3">
          <div className="col-12">
            <div className="d-flex flex-row align-items-center  justify-content-start">
              <div
                className="mr-3"
                style={{ cursor: "pointer" }}
                onClick={() => close(false)}
              >
                <IconContext.Provider
                  value={{
                    size: "40px",
                    color: "#6a707e",
                  }}
                >
                  <MdKeyboardBackspace />
                </IconContext.Provider>
              </div>

              <h4 className="header-text " style={{ marginLeft: "20px" }}>
                {data.edit
                  ? "Modify Energy saving measure"
                  : "Create  Energy saving measure"}
              </h4>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="loader-position">loading...</div>
        ) : (
          <>
            <div className="card-container">
              <Formik
                initialValues={initialVal}
                enableReinitialize
                validateOnChange={true}
                validateOnBlur={true}
                validationSchema={yup.object({
                  category: yup.number().required("Category is required"),
                  appliances: yup.string().required("Appliances is required"),
                  measures: yup
                    .array()
                    .of(yup.string().required("Field cannot be empty"))
                    .min(1, "Add atleast one measure ")
                    .required("measure is required"),
                })}
                onSubmit={async (values) => {
                  //  console.log({ values });
                  setLoading(true);
                  try {
                    let URL = GLOBAL_URL_ADMIN + "/measures/add";
                    let VAL = {
                      measures: values.measures.toString(),
                      category: values.category,
                      appliances: values.appliances,
                    };
                    if (data.edit) {
                      URL = GLOBAL_URL_ADMIN + "/measures/update";
                      VAL = {
                        measures: values.measures.toString(),
                        category: values.category,
                        appliances: values.appliances,

                        measure_id: data.index,
                      };
                    }

                    const req = await axios.post(URL, VAL, CONFIG());

                    if (req.status === 200) {
                      toaster.notify(
                        data.edit
                          ? "Energy measures has been Updated"
                          : "Energy measures has been added",
                        "success"
                      );
                      setLoading(false);
                      close({
                        state: false,
                        data: null,
                        edit: false,
                        index: "",
                      });
                    }
                  } catch (error) {
                    console.log(error.response);
                    setLoading(false);
                    if (error.response.status === 401) {
                      toaster.notify("Unauthorised Access", "error");
                    } else {
                      toaster.notify(
                        data.edit
                          ? "Failed to update Energy measures"
                          : "Failed to add Energy measures",
                        "error"
                      );
                    }
                  }
                }}
              >
                {(formik) => {
                  return (
                    <>
                      <div
                        className="form-group mb-3"
                        style={{ position: "relative" }}
                        id="measures_c"
                      >
                        <label htmlFor="status" className="create-label">
                          Category {required}
                        </label>
                        <Select
                          options={appsTypesList}
                          onChange={(p) => {
                            formik.setFieldValue("category", p.value);
                          }}
                          value={appsTypesList.filter(
                            (cd) => cd.value === formik.values.category
                          )}
                          isSearchable={false}
                          styles={{
                            container: (provided, state) => ({
                              ...provided,
                              border:
                                formik.touched.category &&
                                formik.errors.category
                                  ? "1px solid red"
                                  : "1px solid #B3CDFF80",
                            }),
                          }}
                        />

                        {formik.touched.category && formik.errors.category && (
                          <div className="tariff-error-position">
                            <p>{formik.errors.category}</p>
                          </div>
                        )}
                      </div>

                      <div
                        className="form-group mb-3"
                        style={{ position: "relative" }}
                        id="measures_c"
                      >
                        <label htmlFor="measures" className="create-label">
                          Appliance {required}
                        </label>
                        <input
                          value={formik.values.appliances}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "appliances",
                              e.currentTarget.value.replace(
                                /[^a-zA-Z0-9? ]/g,
                                ""
                              )
                            );
                          }}
                          className="form-control addInput"
                          placeholder="Energy saving measure"
                          id="measures"
                          style={{
                            border:
                              formik.touched.appliances &&
                              formik.errors.appliances
                                ? "1px solid red"
                                : "1px solid #B3CDFF80",
                          }}
                        />

                        {formik.touched.appliances && formik.errors.appliances && (
                          <div className="tariff-error-position">
                            <p>{formik.errors.appliances}</p>
                          </div>
                        )}
                      </div>

                      <div
                        className="form-group mb-3"
                        style={{ position: "relative" }}
                        id="measures_c"
                      >
                        <div>
                          <FieldArray name={"measures"}>
                            {({ form, push, remove }) => {
                              const { values } = form;
                              const { measures } = values;
                              const onAddClick = () => {
                                push("");
                              };

                              return (
                                <>
                                  <div className="d-flex flex-row justify-content-between align-items-center ">
                                    <label
                                      htmlFor="title"
                                      className="create-label"
                                    >
                                      Energy Measure {required}
                                    </label>
                                    <p
                                      onClick={onAddClick}
                                      className="desc-link"
                                    >
                                      add
                                    </p>
                                  </div>
                                  {measures.length
                                    ? measures.map((td, i) => {
                                        return (
                                          <>
                                            <div className="desc-box">
                                              <div className="desc-input">
                                                <input
                                                  value={td}
                                                  onChange={(e) => {
                                                    formik.setFieldValue(
                                                      `measures[${i}]`,
                                                      e.currentTarget.value.replace(
                                                        /[^a-zA-Z0-9!().-_,./& ]/g,
                                                        ""
                                                      )
                                                    );
                                                  }}
                                                  style={{
                                                    border:
                                                      formik.errors.measures &&
                                                      formik.errors.measures[i]
                                                        ? "1px solid red"
                                                        : "1px solid #B3CDFF80",
                                                  }}
                                                  className="form-control addInput"
                                                  placeholder="Appliance name"
                                                  id="appliance_name"
                                                />
                                                {formik.errors.measures &&
                                                  formik.errors.measures[i] && (
                                                    <div className="tariff-error-position">
                                                      <p>
                                                        {
                                                          formik.errors
                                                            .measures[i]
                                                        }
                                                      </p>
                                                    </div>
                                                  )}
                                              </div>
                                              {i > 0 && (
                                                <div>
                                                  <p
                                                    onClick={() => remove(i)}
                                                    className="desc-remove"
                                                  >
                                                    remove
                                                  </p>
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        );
                                      })
                                    : null}
                                </>
                              );
                            }}
                          </FieldArray>
                        </div>
                      </div>

                      <div className="d-flex flex-row align-items-center justify-content-end mt-4 pt-3">
                        {data.edit ? (
                          <Button
                            variant="contained"
                            id="deleteBtn"
                            className={"deleteBtn"}
                            onClick={() => setModalShow(true)}
                            disabled={loading}
                          >
                            Delete
                          </Button>
                        ) : null}
                        <Button
                          variant="contained"
                          id="createBtn"
                          className={"createBtn"}
                          onClick={formik.handleSubmit}
                          disabled={loading}
                        >
                          {data.edit ? "Update" : "Add"}
                        </Button>
                      </div>
                    </>
                  );
                }}
              </Formik>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(EditMeasures);
