import React, { useEffect, useState, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import "../assets/css/apps.css";

import { BsPlusCircleFill } from "react-icons/bs";
import { RiEditBoxFill } from "react-icons/ri";

import { IconContext } from "react-icons";

import { GLOBAL_URL_ADMIN, CONFIG } from "../config";
import axios from "axios";

import EditMeasures from "./EditMeasures";

const EnergyMeasures = (props) => {
  const [measureList, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editView, setEditView] = useState({
    state: false,
    edit: false,
    index: "",
    data: "",
  });

  const getData = useCallback(async () => {
    try {
      const response = await axios.get(
        GLOBAL_URL_ADMIN + "/measures",
        CONFIG()
      );
      if (response.status === 200) {
        setList(response.data.data);
        return setLoading(false);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.status !== undefined
      ) {
        console.log("error");
        // return props.history.replace("/");
      }
    }
  }, []);

  useEffect(() => {
    getData();
  }, [editView.state]);
  return (
    <>
      {loading ? (
        <div className="loader-position">loading...</div>
      ) : editView.state ? (
        <EditMeasures close={setEditView} data={editView} />
      ) : (
        <div className="w-100">
          <div className="row align-items-center mb-3">
            <div className="col-9">
              <h4 className="header-text">Energy Saving Measures</h4>
            </div>
            <div className="col-3">
              <div className="d-flex flex-row align-items-center  justify-content-end">
                <div
                  className="mr-3"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setEditView({ ...editView, state: true, edit: false })
                  }
                >
                  <IconContext.Provider
                    value={{
                      size: "40px",
                      color: "#42BD41",
                    }}
                  >
                    <BsPlusCircleFill />
                  </IconContext.Provider>
                </div>
                <div className="ml-4" style={{ marginLeft: "15px" }}>
                  Add
                </div>
              </div>
            </div>
          </div>

          <div className="table-responsive table-responsive-md table-responsive-sm main-table">
            <table className="table  table-data">
              <thead>
                <tr className="table-header-text">
                  <th scope="col">#</th>

                  <th scope="col">Energy Measure </th>

                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {measureList.length ? (
                  measureList.map((tdata, i) => {
                    return (
                      <tr className="table-data-row" key={i}>
                        <td>{i + 1}</td>
                        <td>{tdata.measures}</td>

                        <td>
                          <div
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setEditView({
                                state: true,
                                edit: true,
                                data: tdata,
                                index: tdata.id,
                              })
                            }
                          >
                            <IconContext.Provider
                              value={{ size: 30, color: "#e37d20" }}
                            >
                              <RiEditBoxFill />
                            </IconContext.Provider>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={3}>No Data found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(EnergyMeasures);
