import logo from "./logo.svg";
import "./App.css";
import { Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Tool from "./pages/Tool";
import Home from "./pages/Home";
import { ToastProvider } from "./Context/ToastContext";

function App() {
  return (
    <div className="container-fluid layout">
      <div>
        <ToastProvider>
          <Routes>
            <Route index exact path="/" element={<Login />} />

            <Route exact path={"/tool"} element={<Tool />} />

            <Route exact path={"/home"} element={<Home />} />
            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                </main>
              }
            />
          </Routes>
        </ToastProvider>
      </div>
    </div>
  );
}

export default App;
