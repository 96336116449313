import React, { useEffect, useState, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import "../assets/css/apps.css";
import TablePagination from "@mui/material/TablePagination";

import { BsPlusCircleFill } from "react-icons/bs";
import { RiEditBoxFill } from "react-icons/ri";

import { IconContext } from "react-icons";

import EditLeads from "./EditLeads";

import { GLOBAL_URL_ADMIN, CONFIG } from "../config";
import axios from "axios";
import moment from "moment";

const Leads = (props) => {
  const [leads, setList] = useState([]);
  const [pageLeads, setPageLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editView, setEditView] = useState({
    state: false,
    edit: false,
    index: "",
    data: "",
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  //   const handleChangePage = (event, newPage) => {
  //     setPage(newPage);
  //   };

  //   const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(parseInt(event.target.value, 10));
  //     setPage(0);
  //     };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    const total = leads.length;
    let startIndex = (newPage + 1) * rowsPerPage - rowsPerPage;

    const endIndex = startIndex + rowsPerPage;
    const data = leads.slice(startIndex, endIndex);

    setPageLeads([...data]);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    let startIndex = 1 * event.target.value - event.target.value;

    const endIndex = startIndex + event.target.value;
    const data = leads.slice(startIndex, endIndex);

    setPageLeads([...data]);
  };

  const getData = useCallback(async () => {
    try {
      const response = await axios.get(GLOBAL_URL_ADMIN + "/leads", CONFIG());
      if (response.status === 200) {
        const total = response.data.data.length;
        let startIndex = (page + 1) * rowsPerPage - rowsPerPage;

        const endIndex = startIndex + rowsPerPage;
        const data = response.data.data.slice(startIndex, endIndex);

        setPageLeads([...data]);
        setList(response.data.data);
        return setLoading(false);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.status !== undefined
      ) {
        console.log("error");
        // return props.history.replace("/");
      }
    }
  }, []);

  useEffect(() => {
    getData();
  }, [editView.state]);
  return (
    <>
      {loading ? (
        <div className="loader-position">loading...</div>
      ) : editView.state ? (
        <EditLeads close={setEditView} data={editView} />
      ) : (
        <div className="w-100">
          <div className="row align-items-center mb-3">
            <div className="col-9">
              <h4 className="header-text">Leads</h4>
            </div>
            <div className="col-3">
              {/* <div className="d-flex flex-row align-items-center  justify-content-end">
                <div
                  className="mr-3"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setEditView({ ...editView, state: true, edit: false })
                  }
                >
                  <IconContext.Provider
                    value={{
                      size: "40px",
                      color: "#42BD41",
                    }}
                  >
                    <BsPlusCircleFill />
                  </IconContext.Provider>
                </div>
                <div className="ml-4" style={{ marginLeft: "15px" }}>
                  Add
                </div> 
              </div> */}
            </div>
          </div>

          <div className="table-responsive table-responsive-lg table-responsive-md table-responsive-sm main-table">
            <table className="table  table-data">
              <thead>
                <tr className="table-header-text">
                  <th scope="col">#</th>

                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">City</th>
                  <th scope="col">State</th>
                  <th scope="col">EPI</th>

                  <th scope="col">Created Date</th>

                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {leads.length ? (
                  pageLeads.map((tdata, i) => {
                    console.log({ tdata });
                    return (
                      <tr className="table-data-row" key={i}>
                        <td> {page * rowsPerPage + (i + 1)}</td>
                        <td>{tdata.firstname + " " + tdata.lastname}</td>
                        <td>{tdata.email}</td>
                        <td>{tdata.city}</td>
                        <td>{tdata.state}</td>
                        <td className="text-warning ">{tdata.EPI}</td>

                        <td>{moment(tdata.created_at).format("DD-MM-YYYY")}</td>
                        <td>
                          <div
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setEditView({
                                state: true,
                                edit: true,
                                data: tdata,
                                index: tdata.user_id,
                              })
                            }
                          >
                            <IconContext.Provider
                              value={{ size: 30, color: "#e37d20" }}
                            >
                              <RiEditBoxFill />
                            </IconContext.Provider>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <td colSpan={8}>No leads available</td>
                  </>
                )}
              </tbody>
            </table>
            {leads.length > 5 ? (
              <div>
                <TablePagination
                  component="div"
                  count={leads.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 50]}
                />
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default Leads;
